:root {
  --orange: #9966CC;
  --light-black: #18191b;
  --white: white;
}

.swiper-scrollbar-drag {
  background: var(
    --swiper-scrollbar-drag-bg-color,
    rgba(255, 255, 255, 0.5)
  ) !important;
}
.swiper-pagination-bullet-active {
  background: #9966CC;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, #9966CC) !important;
}

.slider-container .left-side .left-side-content .link {
  text-decoration: none;
}
.slider-container .left-side img {
  opacity: .2;
}
@media (min-width: 1100px) {
  .slider-container {
    width: 100%;
    display: flex;
    padding: 50px;
  }

  .slider-container .left-side {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: var(--white);
  }

  .slider-container .left-side .left-side-content {
    width: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10;
    padding: calc(2vw + 1rem);
  }

  .slider-container .left-side .left-side-content button, .slider-container .left-side .left-side-content a.explore{
    text-transform: uppercase;
    font-family: "ArtifaktLegend", sans-serif;
    font-size: 14px;
    padding: 9px 21px;
    line-height: 29px;
    height: 46px;
    background-color: var(--orange);
    border: none;
    outline: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
    color: var(--white);
  }

  .slider-container .left-side .left-side-content h2 {
    font-size: calc(1vw + 1.3rem);
    font-weight: 700;
  }

  .slider-container .left-side .left-side-content p {
    margin: calc(1vw + 10px) 0;
    font-size: calc(0.6vw + 0.37rem);
  }

  .slider-container .left-side {
    overflow:hidden;
    text-align:justify;
  }
  .slider-container .left-side img {
    position: absolute;
    width: 100%;
    left: 0;
    all:0;
  }

  .slider-container .right-side {
    width: 30%;
    /* background-color: var(--orange); */
    background-color: #fff;
    /* background: url(../Images/bg-home.png) bottom; */
    display: flex;
    align-items: center;
    padding: 30px;
  }

  .slider-container .right-side .book-detail {
    transform: translateX(-10%);
    background-color: var(--white);
    padding: calc(0.8vw + 0.2rem);
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    flex-direction: column;
    align-items: center;
    position: relative;
    width: calc(13vw + 15rem);
  }

  .slider-container .right-side .book-detail img {
    width: 100%; /* Adjust as needed */
    object-fit: contain;
  margin-bottom: calc(1vw + 30px);
}

  .slider-container .right-side .book-detail .detail h2 {
    font-size: calc(1vw + 1rem);
    font-weight: 700;
    margin-bottom: calc(1vw + 10px);
  }
  .slider-container .right-side .book-detail .detail > h2:nth-child(3) {
    font-size: calc(0.6vw + 0.7rem);
  }

  .slider-container .right-side .book-detail .detail p {
    font-size: calc(0.6vw + 0.3rem);
  }
}

@media (max-width: 1099px) {
  .slider-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 1px; */
  }

  .slider-container .left-side {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--white);
  }

  .slider-container .left-side .left-side-content {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10;
  }

  .slider-container .left-side .left-side-content button {
    text-transform: uppercase;
    width: fit-content;
    padding: calc(0.2vw + 0.4rem);
    display: flex;
    justify-content: center;
    background-color: var(--orange);
    border: none;
    outline: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
    color: var(--white);
    /* font-size: calc(0.8vw + 0.6rem); */
    font-size: 14px;
  }

  .slider-container .left-side .left-side-content h2 {
    font-size: calc(2vw + 1rem);
    font-weight: 700;
  }

  .slider-container .left-side .left-side-content p {
    margin: 40px 0;
    font-size: calc(1vw + 0.5rem);
  }

  .slider-container .left-side img {
    position: absolute;
    opacity: .2;
    width: calc(22vw + 590px);
    /* aspect-ratio: 9 / 13; */
    /* left: 15%; */
    top: calc(1vw + 1rem);
  }

  .slider-container .right-side {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    padding: 30px;
  }

  .slider-container .right-side .book-detail {
    background-color: var(--white);
    padding: calc(0.8vw + 0.2rem);
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    flex-direction: column;
    align-items: center;
    position: relative;
    width: calc(13vw + 12rem);
  }

  .slider-container .right-side .book-detail img {
    width: calc(10vw + 10rem + 100px);
    aspect-ratio: 16/9;
    margin-bottom: calc(1vw + 30px);
  }

  .slider-container .right-side .book-detail .detail h2 {
    font-size: calc(1vw + 1rem);
    font-weight: 700;
    margin-bottom: calc(1vw + 10px);
  }

  .slider-container .right-side .book-detail .detail p {
    font-size: calc(0.6vw + 0.3rem);
  }
}