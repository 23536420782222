@media (max-width: 870px) {
  .assistance-steps {
    display: none;
  }
}

.steps-bx {
  padding: 15px;
  border: 2px solid #be8400;
  border-radius: 20px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.steps-bx .step-txt span {
  display: flex;
  align-items: center;
  font-size: calc(0.8vw + 0.5rem);
}

.steps-bx .step-txt span i {
  display: flex;
  margin-right: 5px;
}

.core-desc p {
  font-size: calc(1vw + 0.3rem);
}

.number-large {
  display: flex;
  justify-content: center;
}

/* Real state Css */

.boxes {
  display: flex;
  justify-content: center;
  gap: 8px;
  /* align-items: center; */
  width: 100%;

}

.boxes div {
  /* box-shadow: 0px 0px 20px 0px rgba(128, 128, 128, 0.444); */
  border-radius: 15px 15px 0px 0px;
  position: relative;
  top: -45px;
  background-color: white;
  padding: 40px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}

.boxes div:hover {
  cursor: pointer;
  top: -62px;
}

.boxes>div:nth-child(1) {
  border-bottom: 3px solid #5b99f7;
}

.boxes>div:nth-child(2) {
  border-bottom: 3px solid #fbb83d;

}

.boxes>div:nth-child(3) {
  border-bottom: 3px solid #6be5c7;

}

.boxes>div:nth-child(4) {
  border-bottom: 3px solid #f96a67;

}

.real-state-main {
  background-color: #ffefe3;
  background-image: url('../Images/RealState/texture.png');
}


.secondBox {
  gap: 20px;
  margin: 30px 0px;
  display: flex;
  justify-content: space-around;
}

.secondOne {
  width: 40%;
}

.secondOne h2 {
  font-size: 62px;
  color: black;
  font-weight: bold;
  line-height: 90px;
}


.secondTwo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 50%;
}

.secondTwo p {
  letter-spacing: 1px;
  font-size: 22px;
  line-height: 45px;
}

.secondTwo button {
  outline: none;
  border: none;
  color: #000;
  line-height: 45px;
  /* border-radius: 28px; */
  font-size: 16px;
  font-weight: 700;
  padding: 0 36px;
  background: #daab59;
  transition: all .4s ease-in-out;
}

.secondBox {
  padding: 50px 0px;
}

.secondTwo button:hover {
  background-color: #000;
  color: white;
}

.thirdBox {
  height: auto;
  padding-bottom: 60px;
}

.thirdBox img {
  height: 650px;
  width: 100%;
}

.our-projects {
  padding-bottom: 60px;
  width: 100%;
  background-color: #f2ece9;
}

.hideSpace {
  height: 30px;
  position: relative;
  color: transparent;
  width: 100%;
  background-color: #ffefe3;
  background-image: url('../Images/RealState/texture.png');
}

.hideSpace div {
  height: 30px;
  position: relative;
  color: transparent;
  width: 100%;
  top: 30px;
  background-color: #ffefe3;
  background-image: url('../Images/RealState/texture.png');
}


.our-headings h3 {
  color: #f98d8c;
}

.our-headings h1 {
  color: #000;
  width: 50%;
  text-align: center;
  font-size: 62px;
  font-weight: bold;
}

.our-headings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0px;
}

.projects-view {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

.projects-view div {
  padding: 7px;
  background-color: white;
  border-radius: 15px;
  width: 28%;
}

.projects-view div div {
  width: 100%;
}

.projects-view div h4 {
  font-size: 27px;
  letter-spacing: 1px;
  font-weight: bold;
}

.projects-view img {
  border-radius: 10px;
  height: 300px;
  width: 100%;
}

.projects-view .productData {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.projects-view .productData button {
  text-wrap: nowrap;
  align-content: flex-end;
  outline: none;
  border: 2px solid #daab59;
  color: #000;
  line-height: 45px;
  /* border-radius: 28px; */
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  background: linear-gradient(to right, transparent 50%, #daab59 50%);
  background-size: 200% 100%;
  transition: all .4s ease-in-out;
}

#backBtn {
  position: sticky;
  top: 91px;
  left: 10px;
  z-index: 100;
  outline: none;
  border: none;
  color: #000;
  line-height: 45px;
  /* border-radius: 28px; */
  font-size: 16px;
  font-weight: 700;
  padding: 0 36px;
  background: #daab59;
  transition: all .4s ease-in-out;
}

#backBtn:hover {
  background-color: #000;
  color: white;
}

.projects-view .productData button:hover {
  color: white;
  background-position: right center;
}

.fourthBox {
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

.fourthBox img {
  width: 50%;
}

.fourthBox div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  width: 46%;
  gap: 30px;
}

.fourthBox div>p:nth-child(1) {
  color: #5cccb1;
  font-size: 20px;
}

.fourthBox div>p:nth-child(2) {
  font-size: 20px;
}

.fourthBox h4 {
  margin: 0px;
  color: #000;
  font-size: 63px;
  letter-spacing: 1px;
  font-weight: bold;
}

.overview {
  padding: 0px 50px;
}

.fifthBox {
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.ourTeamHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.ourTeamHeading h2 {
  color: #000;
  width: 43%;
  font-size: 62px;
  font-weight: bold;
}

.ourTeambtn {
  text-wrap: nowrap;
  text-decoration: none;
  outline: none;
  border: none;
  color: #000;
  line-height: 45px;
  /* border-radius: 8px; */
  font-size: 16px;
  font-weight: 800;
  padding: 0px 36px;
  background: #daab59;
  transition: all .4s ease-in-out;
}

.ourTeambtn:hover {
  background-color: #000;
  color: white;
}


.ImagesContainer {
  margin: 40px 0px;
  display: flex;
  justify-content: space-around;
}


.ImagesContainer>div {
  width: 23%;
}

.ImagesContainer div img {
  border-radius: 50%;
  width: 100%;
}

.aboutText h5 {
  font-size: 21px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}

.aboutText p {
  font-size: 17px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}

.aboutText {
  width: 100% !important;
  position: relative;
}

.aboutText div {
  width: 100% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 40px;
  position: absolute;
}

.aboutText * {
  text-align: center;
  color: white;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.topRealstateP {
  padding-bottom: 20px;
}

.mainImageDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainImageDiv img {
  width: 80%;
  max-height: 650px;
}

.projectReal {
  margin: 30px 0px;
  text-align: center;
  font-size: 60px;
  color: #000;
  font-weight: bold;
}

@media (max-width:1200px) {
  .secondOne h2 {
    font-size: 48px;
  }

  .projectReal {
    font-size: 48px;
  }

  .ourTeamHeading h2 {
    font-size: 48px;
  }

  .our-headings h1 {
    font-size: 48px;
  }

  .fourthBox h4 {
    font-size: 48px;
  }

  .secondBox {
    flex-direction: column;
  }

  .fourthBox {
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
  }

  .fourthBox img {
    width: 80%;
  }

  .fourthBox div {
    width: 80%;
  }

  .secondOne {
    width: 100%;
  }

  .secondTwo {
    width: 100%;
  }

  .ourTeamHeading {
    width: 80%;
    margin: auto;
  }
}

.insideView {
  padding-bottom: 30px;
  background-color: #ffefe3;
  background-image: url('../Images/RealState/texture.png');
  display: flex;
  flex-direction: column;
}

.insideView h5 {
  padding: 10px;
  color: black;
  text-align: center;
  font-size: 48px;
}

.projectImages {
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.projectImages img {
  height: 250px;
  width: 30%;
}

@media (max-width:1000px) {
  .ImagesContainer>div {
    width: 27%;
  }

  .aboutText div {
    bottom: 20px;
  }

  .aboutText h5 {
    font-size: 17px;
  }

  .aboutText p {
    font-size: 14px;
  }

  .ourTeamHeading h2 {
    width: 100%;
  }

  .secondBox {
    width: 80%;
    margin: auto;
  }

  .secondOne h2 {
    margin: 0px;
    width: 85%;
    font-size: 32px;
    line-height: 45px;
  }

  .projectReal {
    font-size: 32px;
  }

  .our-headings h3 {
    font-size: 18px;
  }

  .fourthBox div>p:nth-child(1) {
    font-size: 18px;
  }


  .ourTeamHeading h2 {
    font-size: 32px;
  }

  .ourTeambtn {
    line-height: 40px;
    font-size: 40px;
    padding: 0px 20px;
    font-size: 14px;
  }

  .realStateHeading {
    font-size: 60px !important;
  }

  .boxes {
    flex-wrap: wrap;
    gap: 20px;
  }

  .boxes div {
    padding: 40px 0px;
    width: 45%;
  }

  .projects-view div {
    width: 42%;
  }

  .projects-view {
    gap: 20px;
    flex-wrap: wrap;
  }


  .our-headings h1 {
    width: 80%;
    font-size: 32px;
  }

  .fourthBox h4 {
    font-size: 32px;
  }
}


@media (max-width:720px) {
  .projectImages {
    gap: 20px;
  }

  .projectImages img {
    height: 200px;
    width: 45%;
  }

  .aboutText h5 {
    font-size: 15px;
  }

  .aboutText p {
    font-size: 12px;
  }

  .ImagesContainer>div {
    width: 35%;
  }

  .ImagesContainer {
    gap: 30px;
    flex-wrap: wrap;
  }


  .fifthBox {
    padding: 0px 20px;
  }

  .ourTeamHeading {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin: 0px;
  }

  .secondOne h2 {
    width: 100%;
  }

  .secondBox {
    width: 100%;
    margin: auto;
  }

  .secondOne {
    width: 100%;
  }

  .realStateHeading {
    top: 30% !important;
    font-size: 45px !important;
  }

  .fourthBox div {
    gap: 20px;
  }

  .fourthBox img {
    width: 100%;
  }

  .fourthBox div {
    width: 100%;
  }


  .projects-view .productData button {
    line-height: 40px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 25px;
  }

  .projects-view div h4 {
    text-wrap: nowrap;
    font-size: 20px;
  }

  .projects-view img {
    height: 230px;
  }

  .projects-view {
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .projects-view div {
    padding: 5px;
    width: 47%;
  }

  .our-headings h3 {
    font-size: 15px;
  }

  .our-headings h1 {
    font-size: 32px;
  }

  .thirdBox img {
    height: 300px;
    width: 100%;
  }

  .secondOne h2 {
    line-height: normal;
  }

  .secondTwo p {
    font-size: 18px;
    line-height: 35px;
  }

  .boxes div strong {
    font-size: 15px;
  }

  .secondTwo button {
    font-size: 15px;
    line-height: 45px;
  }

  .boxes div img {
    height: 40px;
    width: 40px;
  }
}

@media (max-width:450px) {
  .aboutText h5 {
    font-size: 13px;
  }

  .aboutText p {
    font-size: 11px;
  }

  .ImagesContainer>div {
    width: 40%;
  }

  .our-headings h1 {
    width: 100%;
  }

  .overview {
    padding: 0px 20px;
  }

  .fourthBox div>p:nth-child(1) {
    font-size: 16px;
  }

  .fourthBox div>p:nth-child(2) {
    font-size: 16px;
  }

  .fourthBox h4 {
    font-size: 30px;
  }

  .projects-view .productData {
    padding: 5px;
  }

  .projects-view .productData h4 {
    font-size: 16px;
  }

  .projects-view img {
    height: 170px;
  }
}

