/* <style> */
@import url("https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap");
/* </style>  */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: ArtifaktLegend;
  /* src: url(../public/assets/Artifakt-Element-Medium.woff2); */
  src: url(../public/assets/ArtifaktElement-Regular.woff.ttf);
}

/* @font-face {
    font-family: 'ArtifaktLegend', sans-serif ;

  src: url(../public/assets/Artifakt-Legend-Bold.woff.ttf);
} */
@font-face {
  font-family: "teko";
  src: url(../public/assets/Teko/Teko-Bold.ttf);
}

@font-face {
  font-family: ArtifaktLegend3;
  src: url(../public/assets/Artifakt-Element-Regular.woff2);
}

@font-face {
  font-family: "ArtifaktLegend", sans-serif;
  src: url(../public/assets/ArtifaktElement-Regular.ttf);
}

/* @font-face {
  font-family:  ArtifaktLegend2;
  src: url(../public/assets/arial-1.ttf);
} */

/* @font-face {
    font-family: 'ArtifaktLegend', sans-serif ;

  src: url(../public/assets/Helvetica-Bold.ttf);
} */

@import url(https://db.onlinewebfonts.com/c/2ab02c2937018ee7f2ccc346929ccf57?family=Artifakt+Element);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "ArtifaktLegend3";
  font-family: "ArtifaktLegend", sans-serif;
}

html,
body {
  width: 100%;
}

body {
  font-family: "ArtifaktLegend", sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.over-x {
  overflow-x: hidden;
}

.sticky-bx {
  position: sticky;
}

.navbar-toggler {
  display: flex;
}

.navbar-toggler i {
  color: #000;
  font-size: 26px;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 0px;
  transition: background-color 0.5s ease;
  z-index: 1000;
}

.home-ban {
  height: 150vh;
  background: #767676;
}

.navbar {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.header .nav {
  width: 100%;
  /* min-height: 10rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 2px solid rgba(255,255,255,.05); */
  transition: height 0.5s ease;
}

.nav-list {
  list-style: none;
  display: flex;
  margin-right: auto;
}

/* .show-1 {
  display: none;
} */
/* 
.show-2 {
  padding-bottom: 10px;
} */

/* .scrolling-active .show-1 {
  display: block;
}

.scrolling-active .show-2 {
  display: none;
}

header:hover .show-1 {
  display: block;
}

header:hover .show-2 {
  display: none;
} */

/* .nav-link{
  margin: 0 2rem;
  position: relative;
} */

header {
  border-bottom: 1px solid gray;
  background: rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* 
header:hover {
  background: rgba(255, 255, 255);

}

header:hover .nav-item a,
header:hover .items a,
header:hover .navbar-brand {
  color: #000 !important;
} */

header:hover .topbar a {
  color: #767676;
}

header:hover .topbar {
  border-bottom: 1px solid #d8d8d8;
  /* transition: border-color 200ms; */
}

.scrolling-active .topbar {
  display: none;
}

.nav-item a,
.about,
.careers {
  margin-right: 5px !important;
  margin-left: 5px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 18px;
  text-decoration: none;
}

.links-li {
  margin-left: auto;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.home-hid {
  display: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

.btn {
  box-shadow: 0 0 0 0 !important;
}

/*Apply styles after scroll*/
.scrolling-active {
  background: #fff;
  box-shadow: 0 0px 1rem rgba(0, 0, 0, 0.5);
}

.scrolling-active .new-sign-up,
.scrolling-active .nav-link,
.scrolling-active .items a,
.scrolling-active .navbar-brand {
  color: #000 !important;
}

.navbar {
  padding: 0px !important;
}

.navbar-scrolling .navbar-brand {
  margin: 0px auto !important;
}

.scrolling-active .navbar {
  display: block !important;
}

.scrolling-active .nav a {
  color: #313131;
}

.scrolling-active .topbar a {
  color: #767676;
}

.scrolling-active #nav-cta {
  background-color: #313131;
  color: #fff;
}

.scrolling-active #nav-cta:hover {
  background-color: #151515;
}

.scrolling-active .nav-link::after {
  background-color: #313131;
}

.container {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.menu-item {
  font-weight: 600;
  display: inline-block;
  position: relative;
  font-size: 18px;
  /* line-height: 60px; */
  letter-spacing: 1px;
  color: #fff;
  vertical-align: baseline;
  padding: 8px 0;
  color: #be8400;
}

.menu-item a:hover {
  /* color: #be8400; */
}

.menu-item-has-children .menu-item {
  padding: 0px;
  font-size: 18px;
}

.footer-menu > li > a {
  position: relative;
}

.footer-menu-list a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 0.3s ease;
  font-family: "ArtifaktLegend", sans-serif !important;
  text-decoration: none;
  position: relative;
  /* padding: 5px 10px; */
  /* height: 80px; */
  /* line-height: 80px; */
}

.footer-menu-list a.active,
.footer-menu-list a:hover {
  /* background: red; */
  color: #be8400;
  position: relative;
}

.footer-menu-list a.active::after,
.footer-menu-list a:hover::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -35px;
  left: 0px;
  background: #be8400;
}

@media (max-width: 766px) {
  .footer-menu-list a.active::after,
  .footer-menu-list a:hover::after {
    display: none;
  }
}

@media (max-width: 570) {
  .footer-menu-list a.active::after,
  .footer-menu-list a:hover::after {
    display: none;
  }
}

.menu > li > a:hover:after {
  position: absolute;
  content: "";
  bottom: 2px;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(1);
  /* background-color: #be8400; */
  height: 2px;
  width: 100%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

@media (min-width: 992px) {
  .menu-item .active::after {
    transform: scale(1);
  }
}

.open-nav-menu {
  display: flex;
  align-items: center;
}

.open-nav-menu span i {
  display: flex;
}

.open-nav-menu .signup-icon {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.open-nav-menu .signup-icon i {
  display: flex;
  font-size: 30px;
}

.sign-up-btn {
  background: #be8400;
  color: #fff !important;
  padding: 8px 15px;
  /* border-radius: 5px; */
  text-align: center;
  font-weight: 400;
  /* ..................... */
  border-radius: 20px;
}

@media (min-width: 992px) {
  .menu > li > a::after,
  .footer-menu > li > a::after {
    position: absolute;
    content: "";
    bottom: -2px;
    left: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: #be8400;
    height: 2px;
    width: 100%;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .close-btn {
    /* width: 45px !important;
  height: 45px !important;  */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* header ends */

.overlay2 {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 1;
  position: absolute;
  top: 0;

  /* top: 0; */
}

.scrolling-active .box-cont {
  justify-content: space-between !important;
}

@media (max-width: 991px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden !important;
  }

  .navbar-collapse {
    background-color: #fff;
  }

  .nav-item a {
    color: #fff !important;
    font-weight: 600 !important;
  }

  .nav-item {
    border-bottom: 1px solid rgb(230, 228, 228);
  }

  .container {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .show-2 {
    display: none;
  }

  .show-1 {
    display: block;
  }
}

.par {
  font-size: 24px;
}

/* header 2 starts */
.header {
  /* position: absolute; */
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  /* padding: 15px; */
}

.header > .container-fluid,
.assistance > .container-fluid,
.cart-sec > .container-fluid {
  width: 84.3%;
}

.header-main {
  /* background-color: #ffffff; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0;
  border-radius: 4px;
}

.uk-navbar-right {
  margin-right: -12px;
}

.header .nav-menu {
  padding: 0 15px;
}

.header .menu > .menu-item {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  margin-bottom: 0px;
}

.header .menu > .menu-item > a {
  display: block;

  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 0.3s ease;
  font-family: "ArtifaktLegend", sans-serif !important;
  text-decoration: none;
  /* padding: 5px 10px; */
  /* height: 80px; */
  /* line-height: 80px; */
}

.scrolling-active .menu > .menu-item > a {
  color: #000;
}

/* .header .menu>.menu-item>a.active {
  color: #BABABA;
} */

.header .menu > .menu-item > a .plus:after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

/* .header .menu>.menu-item>.sub-menu>.menu-item>a:hover,
.header .menu>.menu-item:hover>a {
  color: rgba(240, 237, 237, 0.3);
} */

.header .menu > .menu-item > .min-380 {
  min-width: 380px;
}

.header .menu > .menu-item > .min-250 {
  min-width: 320px;
}

.header .menu > .menu-item > .sub-menu {
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 4px 15px 0px #c6a0531a;

  position: absolute;
  left: 0;
  top: 50px;
  background-color: #eee;
  padding: 10px 0;
  text-decoration: none;
  z-index: 10;
  transform: translateY(10px);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.sub-menu-inner {
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 4px 15px 0px #c6a0531a;

  list-style: none;
  position: absolute;
  right: -155px;
  top: -10px;
  background-color: #ffffff;
  color: #000;
  padding: 10px 0;
  text-decoration: none;

  transform: translateY(10px);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.sub-menu-inner a {
  display: block;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;

  color: #000;
  transition: all 0.3s ease;
  text-transform: capitalize;
  text-decoration: none;
}

@media (min-width: 992px) {
  .header .menu > .menu-item-has-children:hover > .sub-menu {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .menu-item-has-children-inner:hover .sub-menu-inner {
    opacity: 1;
    visibility: visible;
  }

  .header .menu > .menu-item-has-children:hover > a .plus:after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.header .menu > .menu-item > .sub-menu > .menu-item {
  display: block;
}

.header .menu > .menu-item > .sub-menu > .menu-item > a {
  display: block;
  padding: 10px 20px;
  font-weight: 600 !important;
  font-size: 15px;

  color: #000;
  transition: all 0.3s ease;
  text-transform: capitalize;
  text-decoration: none;
}

.header .menu > .menu-item > .sub-menu > .menu-item > a:hover,
.sub-menu-inner a:hover {
  background: #eeeeee;
  color: #be8400;
}

.header .open-nav-menu span i {
  display: flex;
  font-size: 30px;
  color: #fff;
  position: relative;
}

.scrolling-active .open-nav-menu span i {
  color: #000 !important;
}

.header:hover .open-nav-menu span i {
  color: #000;
}

.header .open-nav-menu span:before {
  top: -7px;
}

.header .open-nav-menu span:after {
  top: 7px;
}

.header .close-nav-menu {
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin: 0 0 15px 15px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.header .close-nav-menu .fa-times {
  color: black;
}

.header .menu-overlay {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}


@media (max-width: 1100px) {
  .header .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }

  .menu-item {
    padding: 0px;
  }

  .header .nav-menu {
    position: fixed;
    left: -280px;
    visibility: hidden;
    width: 280px;
    height: 100%;
    top: 0;
    overflow-y: auto;
    background-color: #fff;
    z-index: 1000;
    padding: 15px 0;
    transition: all 0.5s ease;
  }

  .header .nav-menu.open {
    visibility: visible;
    left: 0px;
  }

  .header .menu > .menu-item {
    display: block;
    margin: 0;
  }

  .header .menu > .menu-item > a {
    color: #000;
    padding: 12px 0px;
  }

  .menu {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .header .menu > .menu-item > a .plus:before,
  .header .menu > .menu-item > a .plus:after {
    background-color: #ffffff;
  }

  .header .menu > .menu-item-has-children.active > a .plus:after {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  .header .menu > .menu-item > .sub-menu {
    width: 100%;
    position: relative;
    opacity: 1;
    visibility: visible;
    border: none;
    background-color: transparent;
    box-shadow: none;
    transform: translateY(0px);
    padding: 0px;
    left: auto;
    top: 0px;
    display: none;
    overflow: hidden;
  }

  .header .menu > .menu-item > .sub-open {
    display: block;
  }

  .header .menu > .menu-item > .sub-menu > .menu-item > a {
    padding: 12px 30px;
    color: #000;
  }

  .header .close-nav-menu {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #be8400;
    text-align: center;
  }

  .menu {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .header .menu > .menu-item > .min-380 {
    min-width: auto;
  }

  .header .menu > .menu-item > .min-250 {
    min-width: auto;
  }
}

.header .nav-link {
  padding: 20px 10px !important;
}

.roxi {
  line-height: 40px;
}

/* .mar-left{
  margin-left: 100px !important;
} */

.hed .navbar-brand {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  margin-left: 20px;
}

.scrolling-active .navbar-brand {
  color: #000 !important;
}

/* @media (min-width: 992px) {



  .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      justify-content: end !important;
  }
} */

/* .scrolling-active .navbar {
  display: flex !important;
  justify-content: space-between;
} */

.scrolling-active .navbar .nav-link {
  color: #000;
}

.scrolling-active .rojer {
  display: none !important;
}

.bx-act {
  display: none;
}

.scrolling-active .bx-act {
  display: block;
}

.rojer ul {
  margin: 0px auto !important;
}

.open-nav-menu {
  display: none !important;
}

.mb-view {
  display: none !important;
}

.contact-desk {
  display: flex;
}

.contact-mb {
  display: none !important;
}

@media (max-width: 1100px) {
  .mb-view-none {
    display: none;
  }

  .header > .container-fluid,
  .assistance > .container-fluid,
  .cart-sec > .container-fluid {
    width: 95%;
  }

  .mb-view {
    display: block !important;
  }

  .bx-act {
    display: block;
  }

  .contact-desk {
    display: none !important;
  }

  .open-nav-menu {
    display: flex !important;
    transform: translateX(-20px);
  }

  .contact-mb {
    display: block !important;
  }

  .rojer {
    display: none !important;
  }

  .topbar {
    display: none;
  }

  .box-cont {
    padding: 10px 10px !important;
    justify-content: space-between !important;
  }

  .header {
    background-color: #fff;
  }

  .open-nav-menu span i {
    color: #000 !important;
  }

  .hed .navbar-brand {
    color: #000 !important;
  }
}

/* .rode:hover {
  cursor: pointer !important;
} */
.pt-91 {
  padding-top: 91px;
}

/* testimonials starts */
.mb-90 {
  margin-bottom: 90px;
}

.rating-stars i {
  font-size: 13px;
  margin-right: 3px;
  color: #ff9e2a;
}

.testimonials .previous-btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  bottom: -50px;
  left: 48%;
  transform: translate(-50%, 0);
  border: 1px solid #000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials .next-btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  bottom: -50px;
  left: 52%;
  transform: translate(-50%, 0);
  border: 1px solid #000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

i.arrow {
  display: flex;
}

.testimonial-carousel .owl-dots {
  text-align: center;
}

.slick-dots li button {
  width: 15px !important;
  height: 15px !important;
  background: none !important;
  margin-right: 10px;
  padding: 3px !important;
  border-radius: 50%;
  border: 0.5px solid #be8400 !important;
}

.slick-dots .slick-active button {
  background: #be8400 !important;
}

.slick-dots li button::before {
  content: "" !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.testimonial-carousel .owl-dots .owl-dot.active {
  background: #c6a053;
}

.test-bx .row::before {
  position: absolute;
  content: "";
  background-image: url(../src/Images/chat.png);
  background-repeat: no-repeat;
  height: 128px;
  width: 128px;
  right: 20px;
  z-index: 1;
  opacity: 0.1;
  top: 20px;
}

.test-bx {
  position: relative;
  background: #fff;
  padding: 25px 25px;
}

.pad-20 {
  padding: 20px 0px;
}

.client-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%);
}

.padt-40 {
  padding-top: 30px;
}

.client-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.client-name h3 {
  font-family: ArtifaktLegend, "ArtifaktLegend", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
}

.client-name h3:nth-child(2) {
  font-weight: 500;
  color: rgb(0, 0, 0, 0.9);
}

.client-name p {
  font-family: ArtifaktLegend, "ArtifaktLegend", sans-serif !important;
  font-weight: 500;
  font-size: 15px;
  color: #000;
  margin-bottom: 5px;
}

.client-name .desgn {
  font-weight: 600;
}

.client-text p {
  font-family: ArtifaktLegend, "ArtifaktLegend", sans-serif !important;
  font-size: 16px;
  color: rgba(29, 29, 29, 0.8);
  margin-bottom: 0px;
}

.client-reviews {
  font-size: 16px;
}

.test-slick .slick-list {
  padding: 20px 0px;
}

.test-slick .slick-list .slick-slide > div {
  height: 100%;
}

.test-slick .slick-list .slick-slide .item {
  height: 100%;
}

.test-slick .slick-list .slick-slide .test-bx {
  height: 85%;
}

.rating-stars i {
  font-size: 16px;
  margin-left: 4px;
}

.divider-shape {
  display: inline-block;
  width: 100px;
  height: 1px;
  background: #fff;
  margin-bottom: 10px;
  position: relative;
}

.divider-shape::before {
  position: absolute;
  content: "";
  background: #fff;
  width: 40px;
  height: 1px;
  bottom: 3px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-70 {
  margin-bottom: 70px;
}

.carousel-img {
  text-align: center;
}

.carousel-img img {
  width: 80%;
  margin: 0px auto;
}

.main-title h1 {
  font-family: ArtifaktLegend, "ArtifaktLegend", sans-serif !important;

  font-weight: 700;
  text-align: center;
  font-size: 38px;
  margin-bottom: 15px;
  color: #170802;
}

.testimonials {
  position: relative;
}

.square-bg {
  width: 60%;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
  background: #eeeeee;
}

.numbers-section {
  background: #fff;
}

.about-section {
  background: #000;
}

.number-bx {
  text-align: center;
  padding: 10px;
  background: transparent !important;
}

.number-bx:hover {
  background: transparent !important;
}

.number-large h1 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 5px;
  color: #151515;
  padding: 0;
  -webkit-text-stroke-width: 1.5px;
}

.numbers-section .col-3 {
  width: 25%;
}

.number-desc h2 {
  color: rgb(29, 29, 29);
  font-family: "ArtifaktLegend", sans-serif !important;
  font-weight: 500;
  text-align: center;
  font-size: 15px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.number-desc p {
  color: #22130e;
  /* color: #D6C6B1; */
  font-family: "ArtifaktLegend", sans-serif !important;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  margin-bottom: 5px;
}

/* numbers section ends */

/* footer starts */

.newsletter {
  background: #ffe598;
  border-radius: 61px;
  padding: 30px 30px;
  margin-bottom: -60px !important;
  z-index: 2;
  position: relative;
  /* position: absolute; */
  left: 0px;
  right: 0px;
  top: -50px;
}

footer {
  z-index: 1;
  /* padding: 20px 20px; */
}

.mail-box {
  position: relative;
  width: 100%;
  height: 50px;
}

.mail-box input {
  width: 100%;
  height: 50px;
  padding: 10px 50px;
  border-radius: 25px;
  border: 0.5px solid rgb(25, 25, 25, 0.3);
}

.h2-mid {
  line-height: 50px;
}

.mail-box .mail {
  position: absolute;
  right: 5px;
  height: 40px;
  width: 130px;
  top: 5px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #324fa4 7.27%, #253b7c 88.9%);
}

.pt-lg-80 {
  padding-top: 60px;
}

.footer-bg {
  background: #1d1d1d;
  /* background: linear-gradient(180deg, #C6A053 7.27%, #22130E 88.9%); */
  /* position: relative; */
  padding: 0px 60px;
  /* border-radius: 34px; */
}

.top-border {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-address ul {
  /* list-style-image: url('../Images/address.png'); */

  padding-left: 20px;
}

.footer-address1 ul {
  /* list-style-image: url('../Images/mail.png'); */
  padding-left: 20px;
}

.footer-link ul {
  list-style: none;
}

.footer-link ul li a {
  color: #868e94;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 16px;
  line-height: 25px;
  /* font-family: 'Samsung Sharp Sans', sans-serif; */
}

.footer-address2 li {
  padding-left: 10px;
  line-height: 20px;
}

.main-footer {
  color: #fff;
}

.footer-heading h3 {
  font-family: "ArtifaktLegend", sans-serif !important;
  font-weight: 600;
  /* font-family: 'Samsung Sharp Sans Bold', sans-serif; */
  margin-bottom: 16px;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.footer-desc p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #868e94;
  text-align: justify;
}

.footer-address {
  margin-bottom: 15px;

  font-size: 16px;
}

.footer-address a {
  color: #fff;
  font-weight: 400;
}

.footer-socials .social a,
.in-touch .social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  /* margin-right: 5px; */

  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

.footer-socials .social a {
  /* background: rgba(255, 255, 255, 0.56); */
  color: #fff;
}

.footer-socials .social a:hover {
  color: #be8400;
}

.footer-socials .social {
  margin: 20px;
}

.in-touch .social a {
  background: #dfdfdf;
  color: #253b7c;
}

.footer-bottom-links span {
  padding: 5px 10px;
}

.footer-bottom-links span a {
  color: #000;
  font-size: 14px;
}

.footer-copyright {
  color: #fff;

  font-size: 16px;
}

.footer-bottom-links span:not(:first-child) {
  border-left: 1px solid #a4a4a4;
}

/* footer ends */

/* services starts */
.services-bottom h2 a {
  font-family: "ArtifaktLegend", sans-serif !important;
  font-weight: 600;
  text-align: center;
  font-size: 28px;
  margin-bottom: 15px;
  color: #170802;
  text-decoration: none;
}

.services-top span {
  padding: 10px 20px;
  font-size: 40px;
  background: #c6a053;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: inline-block;
  /* margin-bottom: -60px; */
  transform: skew(-8deg);
  margin-right: 20px;
}

.services-top span i {
  transform: skew(8deg);
  color: #fff;
}

.services-bottom {
  background: #cacbcc;
  padding: 10px 20px;
  height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* services ends */

.intel-hero {
  position: relative;
  height: 90vh;
}

.intel-hero video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  margin-bottom: -5px;
}

.hero-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  /* background: rgb(0, 0, 0, 0.5); */
}

.product-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: rgb(0, 0, 0, 0.4);
}

.logo-img {
  height: auto;
  width: 150px;
  /* background: white; */
  padding: 10px 10px 0px 10px
}

.prod-link {
  background: #1d1d1d;
  color: #fff !important;
  padding: 0px 20px;
  display: inline-block;
  line-height: 36px;
  text-decoration: none;
  /* border-radius: 5px; */
  text-align: center;
  /* border-bottom: ; */
  box-shadow: 0px 3px 0px #939393;
  position: relative;
  transition: 0.3s;
}

.prod-link::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -3px;
  width: 0px;
  transition: 0.3s;
  height: 3px;
  background: #fff;
}

.prod-link:hover {
  color: #fff;
}

.prod-link:hover::after {
  width: 100%;
}

.nav-btn {
  background: #be8400;
  color: #fff !important;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  border: none;
  outline: none;
  padding: 9px 21px;
  text-transform: uppercase;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 400;
}

/* .send-button {
  width: 125.23px;
  height: 50.781px;
} */

.send-button .nav-btn {
  font-size: 14px;
  padding: 9px 21px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 400;
  height: 46px;
}

.text-center .nav-btn {
  padding: 15px 30px;
  line-height: 30px;
  height: 60px;
  text-transform: uppercase;
  font-family: "ArtifaktLegend", sans-serif;
  font-size: 15px;
  padding: 9px 21px;
  line-height: 29px;
  height: 46px;
}

.nav-btn.me-4 {
  text-transform: uppercase;
  font-size: 15px;
  padding: 9px 21px;
  line-height: 29px;
  height: 46px;
  margin-top: 1rem;
}

.nav-btn.btn-w-1 {
  text-transform: uppercase;
  font-size: 15px;
  padding: 9px 21px;
  line-height: 29px;
  height: 46px;
}

.btn-w-1 {
  background: #fff;
  color: #be8400 !important;
}

.nav-btn:hover {
  color: #fff;
}

.nav-btn:hover::after {
  width: 100%;
}

.learn-btn {
  background: #fff;
  color: #1d1d1d !important;
  padding: 0px 20px;
  line-height: 45px;
  text-decoration: none;
  /* border-radius: 5px; */
  text-align: center;
  /* border-bottom: ; */
  box-shadow: 0px 3px 0px #939393;
  position: relative;
  transition: 0.3s;
}

.learn-btn::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -3px;
  width: 0px;
  transition: 0.3s;
  height: 3px;
  background: #1d1d1d;
}

.learn-btn:hover {
  color: #fff;
}

.learn-btn:hover::after {
  width: 100%;
}

.trans-btn {
  background: none;
  color: #1d1d1d !important;
  padding: 0px 20px;
  display: inline-block;
  line-height: 45px;
  text-decoration: none;
  /* border-radius: 5px; */
  text-align: center;
  border: 1px solid #1d1d1d;
  /* border-bottom: ; */
  box-shadow: 0px 3px 0px #939393;
  position: relative;
  transition: 0.3s;
}

.trans-btn::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -3px;
  width: 0px;
  transition: 0.3s;
  height: 3px;
  background: #fff;
}

.trans-btn:hover {
  color: #fff;
}

.trans-btn:hover::after {
  width: 100%;
}

.abt-imgs {
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.abt-imgs::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 2;
}

.abt-imgs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-heading h1 {
  color: #fff;
  font-size: 35px;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
}

.contact-heading {
  margin-top: 40px;
}

.contact-heading h1 {
  color: #000;
  font-size: 35px;
  font-family: "ArtifaktLegend", sans-serif;
  letter-spacing: 0px;
  line-height: 1.3;
  font-weight: 700;
}

.contact-heading h4 {
  color: #4e4e4e;
  font-size: 18px;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
}

.contact-heading p {
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
  font-size: 16px;
  color: rgba(29, 29, 29, 0.8);
  text-align: center;
}

.color-cont-head h1 {
  color: #fff !important;
}

.color-cont-head p {
  color: #ffffff99 !important;
}

.learn-more a {
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;

  font-size: 16px;

  text-decoration: none;
  color: rgba(29, 29, 29);
}

.about-heading p {
  color: rgba(255, 255, 255, 0.6);
}

.service-content p,
.client-text p,
.blog-text p,
.abt-txt p,
.member-txt p,
.tagline {
  text-align: justify;
}

.gr-bg {
  background: #eeeeee;
}

.dark-gr-bg {
  background: rgb(29, 29, 29, 0.3);
}

.blck-bg {
  background: #1d1d1d;
}

.max-600 {
  max-width: 700px;
}

.max-700 {
  max-width: 800px;
}

.contact-form input {
  width: 100%;
  padding: 8px 15px;
  height: 50px;
  /* background: #EEEEEE; */
  /* border-radius: 6px; */
  border: 1px solid rgba(18, 17, 39, 0.12) !important;
  color: #111749;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  /* padding-right: 125px; */
  border: none;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
}

.form-select {
  height: 50px;
  border-radius: 0px !important;
  font-size: 18px;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
}

.enq-plus {
  width: 40px;
  height: 40px;
  background: #1d1d1d;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
}

.enq-plus:hover {
  cursor: pointer;
}

.contact-form .form-check-input:checked[type="radio"] {
  background-color: #0d6efd !important;
}

.contact-page {
  /* border: 2px solid red; */
  background: url("./Images/website-images/contact-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: top;
  background-size: 70%;
}

.signup-form {
  /* border: 2px solid blue; */
  /* filter: blur(10px); */
  background: rgba(240, 239, 239, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(15px);
}

.blure-sign {
  /* border: 2px solid red; */
  width: 32rem;
  height: 40rem;
  position: absolute;
  margin-top: -2rem;
  margin-left: -2rem;
  filter: blur(3px);
  /* background: rgba(27, 27, 27, 0.677); */
}

.signup-form form {
  /* filter: blur(1px); */
  z-index: 2;
  /* border: 2px solid red; */
}

.contact-form label {
  margin-bottom: 10px;
  color: #121127;
  font-size: 16px;
  font-weight: 600;
}

.enquiry-sec .text-start label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 500;
}

.contact-form textarea {
  width: 100%;
  padding: 10px 15px;
  /* background: #EEEEEE; */
  /* border-radius: 6px; */
  border: 1px solid rgba(18, 17, 39, 0.12) !important;
  color: #111749;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  padding-right: 125px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border: 1px solid rgba(18, 17, 39, 0.12) !important;
  background: #eeeeee;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.product-grid .img-wrapper:nth-child(9) {
  /* grid-column: 1/3; */
  grid-row: span 2;
  grid-column: span 2;
}

/* .product-grid .img-wrapper:nth-child(10){
  grid-row: 2/3;
} */
/* .product-grid .img-wrapper:nth-child(9){
  grid-row: 3/3;
  grid-column: 4/5;
} */
/* .product-grid .img-wrapper:nth-child(8){
  grid-row: 3/2;
}
.product-grid .img-wrapper:nth-child(7){
  grid-row: 3/2;
}
.product-grid .img-wrapper:nth-child(6){
  grid-row: 1/2;
}
.product-grid .img-wrapper:nth-child(5){
  grid-row: 2/2;
} */
.img-wrapper {
  position: relative;
  overflow: hidden;
  height: 220px;
}

.h-last {
  height: 440px;
}

.img-wrapper > img {
  display: block;
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  object-position: center;
}

.product-vertical {
  position: absolute;
  left: 0px;
  top: 60%;
  transform: translate(-35%, -50%);
  z-index: 3;
}

.product-vertical h2 {
  color: #fff;
  transform: rotate(-90deg);
  font-size: 13px;
}

.product-vertical h2 span {
  position: relative;
}

.product-vertical h2 span::after {
  content: " ";
  position: absolute;
  right: -35px;
  top: 50%;
  width: 25px;
  height: 2px;
  background: #fff;
}

.product-content h2 {
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 600;
}

.product-content h2 span {
  position: relative;
}

.product-content h2 span::after {
  content: " ";
  position: absolute;
  right: -35px;
  top: 50%;
  width: 25px;
  height: 2px;
  background: #1d1d1d;
}

.img-wrapper > .product-content {
  position: absolute;
  inset: 0;
  font-size: 14px;
  padding: 1rem;
  background: #fff;
  justify-content: center;
  transform: translateX(-100%);
  align-items: center;
  z-index: 3;
  transition: all 0.3s ease-in-out;
}

.img-wrapper:hover > .product-content {
  transform: translateX(0%);
}

/* services starts */
.service-bx {
  overflow: hidden;
  width: 100%;
}

.service-bx-inner {
  background-image: url("../src/Images/abt1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 12;
  transition: 1s ease;
}

.home-serv .col-md-6:nth-child(5) .service-bx {
  background-position: top;
}

.service-bx-inner::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
  z-index: -1;
  transition: 1s ease;
  transform: scale(1);
}

.service-content {
  padding: 80px 50px;
  cursor: pointer !important;
}

.service-content h1 a {
  text-decoration: none;
  /* color: #fff; */
  color: #fff;
  font-size: 28px;
  /* font-family: 'Manrope', sans-serif; */
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
}

.service-content p {
  /* color: #fff; */
  color: #fff;
  font-size: 16px;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 500;
  margin-bottom: 0px;
}

/* Services ends */

/* blogs starts */
.blog-img {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 5px;
}

.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-blg-img {
  width: 100%;
  height: 90px;
  overflow: hidden;
  margin-bottom: 5px;
}

.small-blg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-text h1 {
  font-size: 15px;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
  /* border: 2px solid red; */
  font-weight: 600;
}

.blog-text p {
  color: rgba(29, 29, 29, 0.8);
  font-size: 16px;
}

.blog-date span {
  color: rgba(29, 29, 29, 0.8);
  font-size: 16px;
}

.blg-sm-line:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dee1e6;
}

/* blogs ends */

.first-prod-row .col-6 {
  width: 33.33%;
}

.prod-belarea .col-lg-8 {
  width: 66.66%;
}

.prod-belarea .col-40 {
  width: 33.33%;
}

/* slide animation */
.slide-anim {
  position: relative;
  /* margin: 270px auto 0; */
  width: 100%;
  /* height: 50px; */
  /* background: #34495e; */
  border-radius: 8px;
  font-size: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.slide-anim .col-3 {
  font-size: 15px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  line-height: 50px;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-align: center;
}

.numbers-section .row,
.home-prod .row {
  --bs-gutter-x: 0rem;
}

.slide-anim .animation {
  position: absolute;
  /* height: 100%; */
  /* height: 5px; */
  top: 0;
  left: 0;
  /* bottom: 0; */
  z-index: 0;
  opacity: 0;
  background: transparent;
  /* background: rgb(29, 29, 29,.6); */
  height: 100%;
  /* border-radius: 8px; */
  transition: all 0.5s ease 0s;
}

.slide-anim:hover .animation {
  opacity: 1;
}

.slide-anim .col-3:nth-child(1) {
  width: 25%;
}

/* .slide-anim .col-3:nth-child(1) .number-large h1 {
  color: #fff !important;
} */

/* .slide-anim .col-3:nth-child(1):hover .number-large h1{
  color: #fff !important;
} */

/* .slide-anim .col-3:nth-child(1):hover .number-large, .slide-anim .col-3:nth-child(2):hover .number-large, .slide-anim .col-3:nth-child(3):hover .number-large, .slide-anim .col-3:nth-child(4):hover .number-large{
  border: 2px solid #fff;
} */
/* .slide-anim .col-3:nth-child(1):hover .number-large h1, */
.slide-anim .col-3:nth-child(1):hover .number-desc h2 {
  color: #000 !important;
}

/* .slide-anim .col-3:nth-child(2):hover .number-large{
  border: 2px solid #fff;
} */
/* .slide-anim .col-3:nth-child(2):hover .number-large h1, */
.slide-anim .col-3:nth-child(2):hover .number-desc h2 {
  color: #000 !important;
}

/* .slide-anim .col-3:nth-child(3):hover .number-large h1, */
.slide-anim .col-3:nth-child(3):hover .number-desc h2 {
  color: #000 !important;
}

/* .slide-anim .col-3:nth-child(4):hover .number-large h1, */
.slide-anim .col-3:nth-child(4):hover .number-desc h2 {
  color: #000 !important;
}

.slide-anim .col-3:nth-child(5):hover .number-desc h2 {
  color: #000 !important;
}

.number-large h1 {
  color: #be8400;
}

.slide-anim
  .col-3:nth-child(1)
  .number-large
  h1
  ~ .slide-anim
  .col-3:nth-child(2):hover
  .number-large
  h1 {
  color: #000 !important;
}

.slide-anim .start-home,
.slide-anim .col-3:nth-child(1):hover ~ .animation {
  width: 25%;
  left: 0;
}

.slide-anim .col-3:nth-child(1):hover ~ .number-large h1 {
  color: #fff !important;
}

.slide-anim .col-3:nth-child(2) {
  width: 25%;
}

.slide-anim .col-3:nth-child(2):hover ~ .animation {
  width: 25%;
  left: 25%;
}

.slide-anim .col-3:nth-child(3) {
  width: 25%;
}

.slide-anim .col-3:nth-child(3):hover ~ .animation {
  width: 25%;
  left: 50%;
}

.slide-anim .col-3:nth-child(4) {
  width: 25%;
}

.slide-anim .col-3:nth-child(4):hover ~ .animation {
  width: 25%;
  left: 75%;
}

.slide-anim .col-3:nth-child(5) {
  width: 25%;
}

/* .slide-anim .col-3:nth-child(5):hover~.animation {
  width: 25%;
  left: 80%;
} */

.services-img {
  width: 100%;
}

/* Services Page starts */

.parallax-window {
  min-height: 100vh;
  background: transparent;
  position: relative;
}

.parallax-window .info {
  font-size: 80px;
  line-height: 1.2em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  animation-delay: 1.5s;
  animation: fade 1.5s ease-out 1s forwards;
}

.parallax-window .info-inn {
  z-index: 2 !important;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto auto !important;
}

.parallax-sec > div > div {
  position: relative;
}

.parallax-sec > div > div::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgb(0, 0, 0, 0.2);
}

.line {
  height: 5px;
  width: 400px;
  opacity: 0;
  position: absolute;

  bottom: 20%;
  left: 110%;
  background-color: #fff;
  animation-delay: 1.5s;
  animation: height1 1.5s ease-out 1s forwards;
}

@keyframes height1 {
  0% {
    width: 0px;
    opacity: 0;
  }

  100% {
    width: 400px;

    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.lib-link {
  text-decoration: none;
  color: #1d1d1d;
  font-weight: 600;
  font-size: 15px;
  /* font-family: 'Manrope', sans-serif !important; */
  font-family: "ArtifaktLegend", sans-serif;
}

.lib-link:hover {
  text-decoration: underline;
  color: #1d1d1d;
}

.serv-cont h3 {
  color: #1d1d1d;
  font-size: 20px;
  /* font-family: 'Manrope', sans-serif; */
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
}

.serv-cont p {
  color: rgba(29, 29, 29, 0.8);
  margin-bottom: 7px;
}

.max-500 {
  max-width: 500px;
}

.inserv-img {
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
}

.team-box {
  border: 1px solid#fff;
}

.blg-img {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.blg-big-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

.blg-large-img {
  width: 100%;
  /* height: 380px; */
  overflow: hidden;
  position: relative;
}

.inserv-img img,
.blg-img img,
.blg-big-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blg-large-img img {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.inserv-inner {
  width: 100%;
  background: #f7f6f4;
  /* padding: 30px 0px; */
  position: relative;
  margin-top: -60px;
}

.inserv-inner p {
  margin-bottom: 0px;
  font-size: 16px;
}

.p-team {
  padding: 0px 50px;
}

.rel-pos {
  position: relative;
}

.certificates .nav-pills .nav-item .nav-link {
  background: none;
  border-radius: 0px;
  font-size: 18px;
  /* border-bottom: 1px solid #1D1D1D; */
  color: #1d1d1d !important;
  padding: 20px 10px;
  margin: 0px !important;
}

.certificates .nav-pills .nav-item .nav-link.active {
  background: #be8400;
  color: #fff !important;
  position: relative;
}

.certificates .nav-pills .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  right: -18px;
  top: 20%;
  width: 0;
  height: 0;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid #be8400;
  clear: both;
}

/* products start */

.side-img img {
  width: 100%;
  height: 400px;
  /* overflow: hidden; */
  position: relative;
}

.prod-side span {
  position: relative;
}

.prod-side span::after {
  content: " ";
  position: absolute;
  width: 20%;
  height: 3px;
  background: #be8400;
  top: -10px;
  left: 0px;
}

.list-products ul li {
  list-style: none;
  position: relative;
}

.list-products ul li::before {
  content: " ";
  position: absolute;
  left: -25px;
  top: 5px;
  font: var(--fa-font-solid);
  content: "\f058";
  color: #be8400;
}

.trusted-img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.trusted-txt h3 {
  color: #1d1d1d;
  /* margin-top: 10px; */
  font-size: 20px;
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
}

.trusted-txt p {
  color: #1d1d1d;
  font-size: 16px;
  font-family: "ArtifaktLegend", sans-serif !important;
  margin-bottom: 0px;
}

.two-img-bx {
  position: relative;
}

.large-img img {
  width: 80%;
}

.small-img {
  position: absolute;
  width: 60%;
  bottom: -30%;
  right: 0px;
}

.small-img img {
  width: 100%;
  object-fit: cover;
}

.strategy-bx {
  /* border: 1px solid #1D1D1D; */
  margin-bottom: 8px;
  height: 100%;
  background: #eeeeee;
}

.strategy-title {
  /* border-bottom: 1px solid #1D1D1D; */
  padding: 15px;

  background: rgb(29, 29, 29, 0.3);
}

.strategy-title i {
  font-size: 20px;
}

.strategy-title h1 {
  color: #1d1d1d;
  /* margin-top: 10px; */
  font-size: 20px;
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
  margin-bottom: 0px;
}

.strategy-text {
  padding: 10px;
}

.head-float {
  text-align: center;
}

@media (min-width: 1100px) {
  .strategies .col-lg-4 {
    width: 20%;
  }

  .strategies .col-lg-4:nth-child(1) .strategy-bx {
    position: relative;
    top: 200px;
    /* margin-bottom: -200px; */
  }

  .strategies .col-lg-4:nth-child(2) .strategy-bx {
    position: relative;
    top: 150px;
  }

  .strategies .col-lg-4:nth-child(3) .strategy-bx {
    position: relative;
    top: 100px;
  }

  .strategies .col-lg-4:nth-child(4) .strategy-bx {
    position: relative;
    top: 50px;
  }

  .strategies {
    margin-bottom: 200px;
    position: relative;
    margin-top: 20px;
  }

  .head-float {
    position: absolute;
    left: 0px;
    top: 0px;
    text-align: flex-start;
  }
}

.award-number {
  border-radius: 50%;
  color: #1d1d1d;
  line-height: 30px;
  width: 30px;

  border: 1px solid #eeeeee;
  display: inline-block !important;
}

.side-slider .slick-dots li {
  margin-left: 10px !important;
}

.side-slider .slick-dots {
  display: flex;
  justify-content: flex-end;
}

.side-slider .slick-dots li.slick-active .award-number {
  background: #eeeeee;
  color: #1d1d1d;
}

.filter-img {
  width: 100%;
  height: 100%;
  /* height: 280px; */
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.row-1 .item {
  padding: 5px;
}

.filter-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.filter-btn {
  border: none;
  padding: 15px;
  text-wrap: nowrap;
  /* font-weight: 600; */
  color: rgba(29, 29, 29, 0.6) !important;
  position: relative;
  background: #fff;
}
@media (max-width: 1440px) {
  .filter-btn {
    padding: 12px 15px;
  }
}

.filter-btn.active {
  font-weight: 600;
  color: rgba(29, 29, 29) !important;
}
.filter-btn:focus-visible {
  border: none !important;
}
.products-tab {
  overflow-x: auto;
  margin: 25px 15px 0 15px;
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  /* border-bottom: 1.5px solid rgba(29, 29, 29); */
}
.products-tab::-webkit-scrollbar {
  display: none;
}

.max-900 {
  max-width: 1000px;
  margin: 0px auto;
  border-bottom: 1.5px solid #bababa;
  margin-bottom: 15px;
}

.service-bx {
  position: relative;
}

.service-gif {
  /* background-image: url("../src/Images/gas-oil-gif.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  position: absolute;
  top: 30%;
  right: 30px;
  height: 100px;
  z-index: 100;
  opacity: 0;
  transition: 1s ease;
}

/* .home-serv .col-md-6:nth-child(1) .service-gif{
  background-image: url("../src/Images/gas-oil-gif.gif");
}
.home-serv .col-md-6:nth-child(2) .service-gif{
  background-image: url("../src/Images/Bim2.gif");
}
.home-serv .col-md-6:nth-child(3) .service-gif{
  background-image: url("../src/Images/real-estate.gif");
}
.home-serv .col-md-6:nth-child(4) .service-gif{
  background-image: url("../src/Images/bim1.gif");
}
.home-serv .col-md-6:nth-child(5) .service-gif{
  background-image: url("../src/Images/sustainable.gif");
}
.home-serv .col-md-6:nth-child(6) .service-gif{
  background-image: url("../src/Images/structuralAudit.gif");
} */

/* .service-bx-inner:hover::after {
  background: none;
} */
.service-bx-inner:hover {
  transform: scale(1.1);
}

.service-content .service-content-inner {
  padding: 10px;
}

/* .service-bx-inner:hover .service-content .service-content-inner{
background: rgb(255, 255, 255, .8);
} */

/* .service-bx:hover .service-bx-inner::after {
 
  background: rgb(0, 0, 0, .4);
 
} */
.service-bx:hover .service-gif {
  opacity: 1;
}

.filter-bx {
  position: relative;
  transition: 1s ease;
}

.filter-img::after {
  content: "";
  background: rgb(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 13;
  transition: 1s ease;
}

.filter-img:hover::after {
  content: "";
  opacity: 1;
  background: rgb(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 13;
  /* transition: 1s ease; */
}

.filter-txt {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 14;
  font-size: 14px;
  transition: 1s ease;
}
.project-title {
  font-size: 22px;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
}
.filter-txt h3 {
  color: #fff;
  /* margin-top: 10px; */
  font-size: 32px;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
}
.filter-txt span {
  color: #fff;

  font-family: "ArtifaktLegend", sans-serif;
  /* font-weight: 700; */
}

.filter-txt h3:hover {
  cursor: pointer;
}

.filter-img:hover .filter-txt {
  opacity: 1;
}

.project-grid {
  content: " ";
  display: table;
}

.project-grid .project-img-wrapper {
  display: table-cell;
  float: none;
  width: 33.333%;
}

#gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

@media (max-width: 1200px) {
  #gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width: 800px) {
  #gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width: 600px) {
  #gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.strategy-text p {
  text-align: justify;
}

@media (max-width: 991px) {
  .intel-hero video {
    margin-top: 70px;
    height: 50vh;
  }

  .product-search-bx .form-sel {
    width: 155px;
    padding-right: 10px;
  }

  .nav-btn-org {
    width: 45%;
    text-transform: uppercase;
  }

  .number-desc h2 {
    color: #000;
    font-size: calc(0.8vw + 0.2rem);
  }

  .number-large h1 {
    color: #be8400;
    -webkit-text-stroke-width: 1.5px;
    font-size: calc(0.2vw + 0.2rem);
  }

  .number-large {
    border: 1.5px solid #fff;
  }

  .slide-anim .animation {
    display: none;
  }

  .first-prod-row .col-6 {
    width: 50%;
  }

  .first-prod-row .col-6:nth-child(5) {
    width: 100%;
  }

  .img-wrapper > .product-content {
    position: absolute;
    font-size: 12px;
  }

  .prod-belarea .col-40 {
    width: 100%;
  }

  .prod-belarea .col-lg-8 {
    width: 100%;
  }

  .img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .h-last {
    height: 220px;
  }

  .service-content {
    padding: 36px;
  }

  .client-name h3 {
    font-size: 14px;
  }

  .client-name p {
    font-size: 11px;
  }

  .testimonials .previous-btn {
    left: 45%;
  }

  .testimonials .next-btn {
    left: 55%;
  }

  .footer-bg {
    padding: 0px 60px;
  }

  .parallax-window {
    min-height: 30vh;
    margin-top: 70px;
  }

  .parallax-window .info {
    font-size: 48px;
  }

  .line {
    height: 3px;
    width: 170px;
  }

  @keyframes height1 {
    0% {
      width: 0px;
      opacity: 0;
    }

    100% {
      width: 170px;

      opacity: 1;
    }
  }

  .two-img-bx {
    position: relative;
    margin-bottom: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .small-img {
    position: absolute;
    width: 60%;
    bottom: -20%;
    right: 0px;
  }
}

.table1 {
  display: table;
  width: 100%;
  text-align: center;
}

.table1-row {
  display: table-row;
  width: 100%;
  text-align: center;
}

.table1-cell {
  display: table-cell;
}

.table1-cell span {
  display: block;
  border: 1px solid;
  min-width: 60px;
  margin: 1px;
}

.awards-rel {
  position: relative;
  /* margin-top: 30px; */
}

.awards-big-txt {
  position: absolute;
  top: -100px;
  right: 0px;
  z-index: 100;
  font-family: "ArtifaktLegend", sans-serif;
}

.awards-big-txt h1 {
  font-size: 100px;
  color: #eeeeee;
  /* color: rgb(0, 0, 0,.1 ); */
  font-weight: 900;
}

.achieve-logo {
  text-align: center;
}

.achieve-logo img {
  width: 35%;
}

@media screen and (max-width: 1000px) {
  .table1,
  .table-row {
    display: block;
  }

  .table1-cell {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  /* .square-bg{
  top: 64.5%;
} */
  .parallax-window .info {
    font-size: 42px;
  }

  .testimonials .previous-btn {
    left: 40%;
  }

  .testimonials .next-btn {
    left: 60%;
  }
}

.new-trust {
  height: 350px;
}

.pt-60 {
  padding-top: 20px;
}

.popup {
  position: fixed;
  top: 0px !important;
  left: 0;
  width: 100%;
  height: 101vh;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.popup-inner {
  position: relative;
  /* padding: 15px; */
  max-width: 700px;
  width: 100%;
  /* min-height: 60vh; */
  background: #fff;
}

.proj-modal .popup-inner {
  width: 100%;
  height: 100%;
  background: none;
  max-width: 100% !important;
}

.proj-modal {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
}

.proj-modal .product-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.proj-modal {
  background: rgb(0, 0, 0, 0.9);
}

.popup-inner .close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 23px;
  top: 20px;
  text-align: center;
  font-size: 18px;
  width: 2rem;
  height: 2rem;
  line-height: 15px;
}

.float-content {
  border-radius: 0% 0% 60% 0%;
  background: rgb(186, 186, 186, 0.75);
  width: 70%;
  padding: 100px 20px;
}

.lines {
  width: 100%;
  height: 31px;
  position: relative;
}

.lines::after {
  content: "";
  position: absolute;
  margin: auto;
  height: 2px;
  background: #fc481f;
  width: 100%;
  top: 45%;
}

.rectangle {
  position: absolute;
  height: 10px;
  width: 67px;
  border: 2px solid #fc481f;
  margin: auto;
  background-color: #fc481f;
  margin-right: 6rem;
  padding-left: 10rem;
  bottom: 1;
  top: 0;
  margin-left: 28rem;
  margin-top: 1rem;
}

.lines::after {
  left: 0;
}

.lines::before {
  right: 0;
}

@media (min-width: 425px) {
  .rectangle {
    position: absolute;
    height: 10px;
    width: 67px;
    border: 2px solid #fc481f;
    background-color: #fc481f;
  }

  .lines {
    width: 100%;
    height: 31px;
    position: relative;
  }
}

.btm_lines {
  width: 100%;
  height: 31px;
  position: absolute;
  bottom: 0;
}

.btm_lines::after {
  content: "";
  position: absolute;
  margin: auto;
  height: 2px;
  background: #fc481f;
  width: 100%;
}

.btm_rectangle {
  height: 10px;
  width: 67px;
  border: 2px solid #fc481f;
  margin: auto;
  background-color: #fc481f;
  margin-right: 6rem;
  padding-left: 10rem;
  bottom: 1;
  top: 0;
  margin-left: 28rem;
}

.float-popup-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.py-60 {
  padding: 60px 0px;
}

.float-txt h2 {
  color: rgb(29, 29, 29);
  font-family: "ArtifaktLegend", sans-serif !important;
  font-weight: 600;
  text-align: flex-start;
  font-size: 20px;
  margin-bottom: 5px;
}

.request-area {
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.request-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(24, 31, 42, 0.5);
  z-index: 1;
}

.z-ind-3 {
  position: relative;
  z-index: 3;
}

.menu-for-large {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translate(0%, -50%);
  z-index: 12;
  width: 100%;
}

.menu-for-large .menu-item a {
  color: #fff;
  font-size: 40px;
  text-decoration: none;
  padding: 10px 25px;
  font-weight: 100px !important;
  filter:drop-shadow(2px 4px 6px black);
}

.menu-for-large .menu-item a:hover {
  color: #be8400;
}

.trusted-img {
  text-align: center;
  display: flex;
  justify-content: center;
}

.modal-content {
  background: none !important;
}

.trusted-img img {
  height: 100%;
  object-fit: contain !important;
  width: auto;
}

.first-trust img {
  height: auto;
  width: 100% !important;
}

.close-btn {
  position: absolute;
  right: 0px;
  z-index: 7;
  top: -50px;
}

.product-modal {
  height: 70vh;
  width: 100%;
}

.product-modal img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.modal-dialog {
  max-width: 800px;
}

.modal.show .modal-dialog {
  transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.float-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100000;
  border: none;
  background: #666666;
  line-height: 50px;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border-bottom-right-radius: 2px;
  color: #fff;
  font-size: 28px;
  box-shadow: 0 0px 1rem rgba(45, 45, 45, 0.5) !important;
}

.float-btn i {
  transform: rotateY(180deg);
}

.contact-form .divider {
  height: 1px !important;
  width: 100% !important;
  background: rgb(29, 29, 29, 0.3) !important;
}

.footer-menu-list ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.footer-menu-list li {
  padding: 20px 0px;
  margin-right: 50px;
  list-style: none;
  white-space: nowrap;
}

.footer-menu-list li a {
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  /* padding: 10px 25px; */
  font-weight: 500;
}

.footer-menu {
  background: #1d1d1d;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-bottom: 2px solid #fff;
}

.px-60 {
  padding: 0px 60px;
}

.product-banner {
  /* height: 100vh; */
  background-image: url("../src/Images/2product.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  padding-top: 150px;
  z-index: 12;
  transition: 1s ease;
}

.product-heading h1 {
  font-size: 72px;
  color: #fff;
  font-weight: 700;
}

.product-buttons {
  font-size: 16px;
  color: #fff;
}

.slogan-icon img {
  width: 64px;
  height: 64px;
  margin-bottom: 5px;
  color: #fff;
}

.slogan-heading h6 {
  color: #fff;
  font-size: 28px;
  white-space: nowrap;
}

@media (max-width: 1100px) {
  .slogan-heading h6 {
    color: #fff;
    font-size: calc(1vw + 0.5rem);
    white-space: nowrap;
  }
}

.product-search-bx {
  max-width: 800px;
  margin: 0px auto;
}

.product-search-bx .form-sel {
  width: 200px;
}

.mb-70 {
  margin-bottom: 80px;
}

.search-btn {
  width: 50px;
  margin-left: -40px;
  background: none;
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* mycss */
.dimsumBackground .shadow{
  background-color: white;
}
.smosaBackground .shadow{
  background-color: white;
}

.SaucesBackground  .shadow{
  background-color: white;
}
.SaucesBackground {
  background-color: #fbf4ee;
}
.smosaBackground{
  /* width: 100%; */
  background-image: url('./Images/dotBackground.svg');
}

.dimsumBackground{
  /* width: 100%; */
  background-image: url('./Images/lineBackground.png');
}

.prod-icon span {
  /* background: #eee; */
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 80px;
  display: inline-block;
}

.prod-icon span *{
  height: 100% !important;
  width: 100% !important;
}

.prod-icon span i {
  /* color: #be8400; */
  font-size: 42px;
}
.prod-icon  i img{
  /* color: #be8400; */
  height: 140px;
  width: auto;
}

.prod-head p {
  font-family: "ArtifaktLegend", sans-serif !important;
  font-size: 16px;
  color: rgba(29, 29, 29, 0.8);
  text-align: justify;
}

.prod-head h1 {
  color: #1d1d1d;
  /* margin-top: 40px; */
  font-size: 20px;
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
  margin-bottom: 15px;
}

.strat-bx {
  position: relative;
}

.strat-number {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.strat-number h1 {
  font-size: 220px;
  color: rgb(29, 29, 29, 0.11);
  font-weight: 800;
  font-family: "ArtifaktLegend", sans-serif;
}

.strat-txt {
  padding-left: 30px;
  padding-top: 100px;
}

.strat-txt h4 {
  font-size: 24px;
  font-family: "ArtifaktLegend", sans-serif;

  font-weight: 700;
}

.strat-txt p {
  text-align: justify;
  font-family: "ArtifaktLegend", sans-serif !important;
  font-size: 16px;
  color: rgba(29, 29, 29, 0.8);
}

.member-txt p {
  font-size: 18px;
}

.about-links ul {
  list-style: none;
  padding-left: 0px;
}

.about-links ul li {
  margin-bottom: 10px;
}

.about-links ul li a {
  text-decoration: none;
  color: #1d1d1d;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 0.3s ease;
  font-family: "ArtifaktLegend", sans-serif !important;
}

.about-links ul li a.active {
  color: #be8400;
  position: relative;
}

/* .about-links ul li a.active::after {
  content: "";
  position: absolute;
  height: 28px;
  width: 2px;
  top: -5px;
  left: -16px;
  background: #be8400;
} */
.masonry-img {
  padding: 10px;
}

.masonry-img img {
  width: 90%;
}

.masonry-img .blog-img-1 {
  margin-bottom: 10px;
}

.blog-text {
  text-align: justify;
}

.masonry-content {
  text-align: center;
  border-bottom: 2px solid #939393;
}

.tm-bx {
  border-bottom: 2px solid #939393;
}

.masonry-content h2 {
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 600;
  font-size: 21px;
}

.modal-backdrop {
  z-index: 10000 !important;
  opacity: 1;
  /* background-image: url(../src/Images/oil-gas.jpg); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

.modal-backdrop::after {
  content: "";
  background: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.modal {
  z-index: 10005 !important;
}

.partners .carousel-img {
  display: flex;
  align-items: center;
}

.partners .carousel-img img {
  width: 50%;
}

.it-3 .carousel-img img {
  width: 28% !important;
}

.careers-img {
  width: 100%;
  height: 100%;
}

.careers-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .side-slider .slick-list{
    padding-left: 300px !important;
  } */

.member-img {
  display: flex;
  justify-content: center;
  width: 25rem;
  height: 320px;
  overflow: hidden;
}

.member-img-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 320px;
  /* padding-right: 20px; */
  overflow: hidden;
  /* background-position-y:top ; */
}

.our-team-sc .member-txt {
  height: 220px !important;
  overflow-y: scroll !important;
  margin-bottom: 10px;
  scrollbar-width: thin !important;
}

.member-img-700 {
  height: 500px;
}

.member-img img,
.member-img-2 img {
  justify-self: center;
  /* width: 25rem; */
  width: 100%;
  height: 25rem;
  object-fit: cover;
  object-position: top;
  /* border: 2px solid red; */
}

.team-post .member-img-2 img {
  object-position: top;
}

.bilal .member-img-2 img {
  object-fit: contain;
  transform: scaleX(70%);
}

/* styled div */
.panel {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.panel.panel-2 {
  height: 55vh;
}

.panel span {
  position: relative;
  display: block;
  overflow: visible;
  top: 40%;
  font-size: 80px;
}

.panel.panel-2 span {
  position: relative;
  display: block;
  overflow: visible;
  top: 55%;
  font-size: 36px;
  transform: translate(0, -50%);
}

.scrll .scrollmagic-pin-spacer {
  height: 55vh !important;
}

.panel.blue {
  background-color: #3883d8;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.panel.blue::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 1;
  /* height: 240px; */
  content: " ";
  display: block;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.panel.panel-2.blue::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 1;
  /* height: 240px; */
  content: " ";
  display: block;
  background: rgb(0, 0, 0, 0.75);
}

.panel.turqoise {
  background-color: #38ced7;
}

.panel.green {
  background-color: #22d659;
  margin-bottom: 800px;
}

.panel.bordeaux {
  background-color: #953543;
}

.over-hide {
  overflow: hidden !important;
}

.pos-rel {
  position: relative;
  background: #fff;
}

footer {
  position: relative;
}

.hero-title {
  color: #b59410;
  position: relative;
  display: block;
  z-index: 5;
}

.hero-title a {
  color: #fff;
  text-decoration: none;
}

/* styled div */
.member-heading h1 {
  color: #000;
  font-size: 38px;
  font-family: "ArtifaktLegend", sans-serif;

  letter-spacing: 0px;
  /* letter-spacing: 1px; */
  line-height: 1.3;
  font-weight: 700;
}

.member-desc h2 {
  /* color: #4E4E4E; */
  font-size: 24px;
  font-weight: 700;
  font-family: "ArtifaktLegend", sans-serif;
}

.member-desc p {
  margin-bottom: 0px;
}

.upload-img {
  /* width: 100%; */
  height: 100px;
}

.upld-btn {
  display: inline-block;
  border: 1px solid rgba(18, 17, 39, 0.12) !important;
  padding: 10px;
}

.contact-form input[type="radio"] {
  width: 1em;
  padding: 0px;
  height: 1em;
}

@media (max-width: 1260px) and (min-width: 992px) {
  .logo-img {
    height: 55px;
  }

  .header .menu > .menu-item > a {
    font-size: 18px;
  }
}

.modal-backdrop.show {
  opacity: 0.5;
}

.carousel-item::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgb(0, 0, 0, 0.4);
  z-index: 2;
}

.carousel-caption {
  z-index: 5;
}

.carousel-caption h3 {
  font-weight: 600;
}

.certificates .nav-pills .nav-item .nav-link {
  font-weight: 600 !important;
}

/* home header starts */

.home-scroll .scrolling-active {
  background: none !important;
  /* background: rgba(255, 255, 255, 0.7 ); */
  box-shadow: None !important;
}

.home-scroll .nav-menu {
  visibility: hidden;
  opacity: 0;
}

.color-header .nav-menu {
  visibility: visible;
  opacity: 1;
}

.color-header .scrolling-active {
  background: #fff !important;
  box-shadow: 0 0px 1rem rgba(0, 0, 0, 0.5) !important;
}

.menu-for-large {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.color-header .menu-for-large {
  opacity: 0;
}

.enquiry-btn {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100000;
  border: 1px solid #fff;
  background: #bababa;
  width: 80px;
  line-height: 80px;
  height: 80px;
  /* border-radius: 50%; */
  color: #fff;
  font-size: 38px;
  box-shadow: 0 0px 1rem rgba(0, 0, 0, 0.5) !important;
}

.footer-menu .footer-socials span a {
  /* background: #1D1D1D; */
  line-height: 45px;
}

.heading-bx {
  position: relative;
}

.head-no h6 {
  font-size: 24px;
  font-weight: 500;
  color: #eeeeee !important;
}

.heading-name h1 {
  font-size: 38px;
  font-weight: 600;
  color: #ffffff !important;
}

.heading-logo {
  position: absolute;
  right: 40px;
  top: 0%;
  z-index: -1;
}

.heading-logo i {
  font-size: 120px;
  color: #eee;
}

.heading-txt p {
  padding-top: 35px;
  font-size: 18px;
  color: #fff;
  font-family: "ArtifaktLegend", sans-serif;
}

.mb-60 {
  margin-bottom: 60px;
}

.form-title h1 {
  font-weight: 600;
}

.team-pos p {
  font-size: 17px;
  color: rgba(29, 29, 29, 0.8);
  font-weight: 600;
  margin-bottom: 15px;
}

.tagline {
  font-size: 18px;
  color: #1d1d1d99;
  /* font-weight: 600; */
}

.pt-40 {
  padding-top: 10px;
}

.team-span-bx {
  border-top: 6px solid rgba(29, 29, 29, 0.8);
  border-bottom: 6px solid rgba(29, 29, 29, 0.8);
  border-left: 6px solid rgba(29, 29, 29, 0.8);
  display: inline-block;
  width: 160px;
  height: 85px;

  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  text-align: center;
  line-height: 70px;
  margin-bottom: 20px;
  padding-left: 10px;
  margin-top: 10px;
}

.about-links ul {
  /* background: #EEE; */
  /* position: -webkit-sticky; 
position: sticky; */

  border-left: 2px solid rgb(147, 147, 147, 0.3);
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  /* padding: 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.team-span-bx img {
  width: 60%;
  /* border:2px solid red; */
}

/* .pt-100{
  padding-top: 100px !important;
} */

.line-right {
  border-right: 1px solid #939393;
}

.line-1 {
  border-top: 1px solid #939393;

  padding-top: 50px;
  padding-bottom: 50px;
}

.stag-2 {
  padding-left: 30px;
}

@media (min-width: 991px) {
  .line-1.col-lg-9 {
    width: 80%;
  }

  .line-1.col-lg-3 {
    width: 20%;
  }
}

.abt-head .header .menu > .menu-item > a {
  color: #1d1d1d !important;
}

.sticky-sec {
  position: sticky;
  top: 35vh;
}

.h-200 {
  height: 200vh;
}

.pos-sticky {
  position: sticky;
  top: 10vh;
}

.pos-sticky p {
  font-size: 16px;
  font-family: "ArtifaktLegend", sans-serif !important;
}

.hexagon {
  padding-top: 40px;
}

.hexagon img {
  height: 24rem;
  width: 100%;
}

.heading-tl {
  position: absolute;
  top: 60%;
  left: -60px;
  transform: translate(0, -50%);
  z-index: 10;
}

.bg-dark-gr {
  background: rgb(29, 29, 29, 0.3);
}

.footer-address .spanbox {
  border: 1px solid #be8400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  text-align: center;
  margin-top: 4px;
}

.footer-address i {
  display: flex;
  color: #be8400;
  font-size: 14px;
}

.footer-address img {
  width: 18px;
}

.read-more-less--more,
.read-more-less--less {
  color: #be8400;
}

.steps-bx span {
  font-size: 18px;
  text-align: center;
}

.step-no {
  position: absolute;
  top: 55%;
  left: -15px;
  transform: translate(-0%, -50%);
}

.step-no span {
  background: #000;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  border-radius: 5px;
}

.steps-bx.active {
  background: #be8400;
}

.steps-bx.active .step-no span {
  background: #fff;
  color: #be8400;
}

.nav-btn-org {
  background: #000;
  color: #fff !important;
  padding: 0px 20px;
  display: inline-block;
  height: 45px;
  width: 33%;
  border-radius: 5px;
  text-decoration: none;
  /* box-shadow: 0px 0px 25px #1D1D1D; */
  /* border-radius: 5px; */
  text-align: center;
  /* border-bottom: ; */
  /* box-shadow: 0px 3px 0px #939393; */
  position: relative;
  transition: 0.3s;
  /* border: 1px solid #1D1D1D; */
}

.discover-btn {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff !important;
  /* padding: 0px 30px; */
  display: inline-block;
  line-height: 45px;
  height: 45px;
  width: 33%;
  /* width: 46.5%; */
  text-decoration: none;
  box-shadow: 0px 0px 25px #1d1d1d;
  /* border-radius: 5px; */
  text-align: center;
  /* border-bottom: ; */
  /* box-shadow: 0px 3px 0px #939393; */
  position: relative;
  transition: 0.3s;
  /* border: 1px solid #1D1D1D; */
}

.w-33 {
  width: 32%;
}

.nav-btn-org-2 {
  /* background: #0072C6; */
  background: #be8400;
  color: #fff !important;
  padding: 0px 20px;
  display: inline-block;
  line-height: 45px;
  height: 45px;
  text-decoration: none;
  text-align: center;
  position: relative;
  transition: 0.3s;
}

.max-400 {
  max-width: 500px;
}

.login-org {
  color: #be8400 !important;
}

.cart-heading h3 {
  font-size: 24px;
  font-weight: 600;
  /* margin-bottom: 30px;/ */
}

.bord-bot {
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
}

.pro-txt h3 {
  font-size: 20px;
}

.pro-txt p {
  font-size: 15px;
  margin-bottom: 0px;
}

.pro-details {
  padding-right: 100px;
}

.btn-grp button {
  background: #fff !important;
  color: #000000 !important;
  border: none !important;
  padding: 3px 8px;

  font-size: 16px;
}

.btn-grp button i {
  font-size: 20px;
}

.btn-grp button.active {
  color: #be8400 !important;
}

.btn-grp {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.trash-btn {
  background: none;
  color: #db202c;
  border: none;
}

.bg-dark-new {
  background: #18191b;
}

.secondary-links ul {
  list-style: none;
  padding-left: 0px;
}

.secondary-links ul li {
  font-size: 18px;
}

.secondary-links ul li a {
  font-size: 18px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  text-wrap: nowrap;
  padding: 0 15px;
}

.secondary-links ul li a.active {
  color: #be8400 !important;
}

.bg-sky {
  background: #f3f6ff;
}

.org-txt {
  color: #be8400 !important;
}

.pad-60 {
  padding: 0px 43px;
}

.pills-li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.pills-li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.color1 {
  color: #be8400;
}

.color2 {
  color: #be8400;
}

.color3 {
  color: #be8400;
}

.core-title h1 {
  color: #000;
}

.padd-100 {
  min-height: 600px;
}

.select-for input {
  background: #fff;
  color: #000;
}

.select-for input:focus {
  background: #fff;
  box-shadow: none;
}

.select-for select {
  background: #be8400;
  color: #fff;
}

.select-for input::placeholder {
  color: rgba(0, 0, 0, 0.5);
  color: #343a40;
}

.form-select {
  color: #000000b3;
}

.select-for .search-btn {
  background: #be8400;
  color: #fff;
  padding: 0px 20px;
  padding-right: 35px;
  font-size: 18px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* .select-for-2 */
.heading-txt {
  text-align: justify;
}

.number-scroll span {
  color: #939393;
  margin-right: 30px;
  font-size: 20px;
}

.number-scroll span.active-no {
  margin-right: 140px;
  position: relative;
}

.number-scroll span.active-no {
  color: #fff;
}

.number-scroll span.active-no::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 35px;
  width: 100px;
  height: 2px;
  background: #fff;
}

.proj-1 h2 {
  font-size: 20px;
  color: #fff;
}

.bg-wht {
  background-color: #fff;
  color: #be8400 !important;
}

.proj-img {
  width: 20%;
}

.ps-50 {
  padding-right: 100px !important;
}

.all-cat {
  position: relative;
  display: inline-block;
  background-image: url("../src/Images/chevron-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px !important;
}

.all-cat::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 10px;
  color: #fff;
  background: red;
  z-index: 100 !important;
}

.learn-more a i {
  color: #be8400;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* sc-gswNZR dpzZiw */

.AWfZQ {
  background-color: #fff !important;
}

.dSMMHt {
  background-color: #be8400 !important;
}

.dpzZiw {
  background-color: #be8400 !important;
}

.jepdwh,
.dRDyYD {
  background: #18191b !important;
}

@media (min-width: 1200px) and (max-width: 1649px) {
  .main-footer .col-lg-3:first-child {
    flex: 0 0 auto;
    width: 30%;
  }

  .main-footer .row {
    justify-content: center;
  }
}

/* home header ends */
@media (min-width: 1650px) {
  .footer-bg {
    padding: 0px 90px;
  }

  .main-footer .col-lg-3:last-child {
    width: 13%;
  }

  .member-img-2 {
    display: flex;
    justify-content: center;
    height: 25rem;
    width: 25rem;
  }

  .footer-address .spanbox img {
    width: 30px;
  }

  .footer-address i {
    font-size: 26px;
  }

  .footer-address .spanbox {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .secondary-links ul li a {
    font-size: 22px;
  }

  .max-400 {
    max-width: 600px;
  }

  .header .menu > .menu-item > a {
    font-size: 18px;
  }

  .header .menu > .menu-item > .sub-menu > .menu-item > a {
    font-size: 18px;
  }

  .sticky-bx {
    position: sticky;
    top: 120px;
  }

  /* .masonry-content h2 {
    font-size: 28px;
  } */

  .head-no h6 {
    font-size: 24px;
  }

  .heading-name h1 {
    font-size: 58px;
  }

  .heading-logo i {
    font-size: 130px;
  }

  .heading-logo {
    right: 60px;
  }

  .heading-txt p {
    font-size: 16px;
  }

  .mb-60 {
    margin-bottom: 80px;
  }

  .masonry-content h6 {
    font-size: 18px;
  }

  .about-links ul li a {
    font-size: 18px;
  }

  .logo-img {
    height: 60px;
  }

  .footer-menu-list li {
    padding: 20px 30px;
  }

  .footer-menu-list li a {
    font-size: 17px;
  }

  .member-img-700 {
    height: 600px;
  }

  .nav-btn,
  .learn-btn,
  .trans-btn,
  .nav-btn-org,
  .nav-btn-org-2 {
    line-height: 60px;
    height: 60px;
    font-size: 20px;
  }

  .abt-imgs {
    height: 250px;
  }

  .about-heading h1,
  .contact-heading h1 {
    font-size: 54px;
  }

  .service-content h1 {
    margin-bottom: 0px;
  }

  .service-content h1 a {
    font-size: 48px;
  }

  .about-heading p,
  .contact-heading p,
  .service-content p,
  .client-text p,
  .client-name h3,
  .blog-text p,
  .product-content p,
  .footer-desc p,
  .footer-address,
  .footer-link ul li a,
  .footer-copyright,
  .serv-cont p,
  .lib-link .trusted-txt p,
  .member-txt p,
  .tagline,
  .prod-head p,
  .strat-txt p {
    font-size: calc(0.76vw + 0.3rem);
  }

  .blog-text {
    font-size: 15px;
  }

  .strat-txt h4 {
    font-size: 28px;
  }

  .learn-more a {
    font-size: 20px;
  }

  .contact-form input {
    height: 60px;
  }

  .img-wrapper {
    height: 360px;
  }

  .h-last {
    height: 720px;
  }

  .blog-img {
    height: 380px;
  }

  .small-blg-img {
    height: 120px;
  }

  .client-name p,
  .rating-stars i {
    font-size: 17px;
  }

  .client-img {
    width: 80px;
    height: 80px;
  }

  .testimonials .previous-btn,
  .testimonials .next-btn {
    bottom: -70px;
  }

  .service-content {
    padding: 160px 50px;
  }

  .footer-socials .social a,
  .trusted-txt h3,
  .serv-cont h3 {
    font-size: 26px;
  }

  .product-content h2 {
    font-size: 24px;
  }

  .blog-date span {
    font-size: 17px;
  }

  .blog-date {
    padding: 10px 0px;
  }

  .product-vertical h2 {
    font-size: 20px;
  }

  .prod-link {
    line-height: 48px;
    font-size: 20px;
  }

  .footer-heading h3 {
    font-size: 28px;
  }

  .footer-desc p {
    font-size: 20px;
  }

  .trusted-img {
    height: 500px;
  }

  .award-number {
    line-height: 40px;
    width: 40px;
    font-size: 22px;
  }

  .side-slider .slick-dots {
    bottom: -45px !important;
  }

  .contact-heading h4 {
    font-size: 22px;
    padding-top: 20px;
  }

  .service-gif {
    height: 160px;
    width: 160px;
  }

  .side-slider .slick-dots li {
    width: 35px;
    height: 35px;
  }

  .certificates .nav-pills .nav-item .nav-link {
    padding: 20px 10px;
  }

  .nav-item a {
    font-size: 26px;
  }

  .list-products li {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .rev-txt h6 {
    font-size: 24px;
  }

  .rev-txt p {
    font-size: 20px;
  }

  .rev-txt p span {
    font-weight: 500;
  }

  .max-600 {
    max-width: 850px;
  }

  .max-700 {
    max-width: 1050px;
  }

  .max-500 {
    max-width: 600px;
  }

  .inserv-img {
    height: 390px;
  }

  .inserv-inner p {
    margin-bottom: 0px;
    font-size: 22px;
  }

  .filter-btn {
    font-size: 26px;
    margin: 4px;
  }

  .service-icon i {
    font-size: 28px;
  }

  .rev-txt img {
    height: 50px;
  }

  .small-img {
    bottom: -20%;
    right: 0px;
  }
}

@media (min-width: 1900px) {
  .header .menu > .menu-item > .min-250 {
    min-width: 500px;
  }
  .pad-60 {
    padding: 0px 80px;
  }

  .bord-bot {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .popup-inner {
    max-width: 700px;
  }
  .cart-heading h3 {
    /* font-size: 28px; */
  }
  .proj-1 h2 {
    /* font-size: 24px; */
    color: #fff;
    padding-left: 20px;
  }

  .float-btn {
    /* font-size: 52px; */
  }

  .pos-sticky p {
    /* font-size: 20px; */
    font-family: "ArtifaktLegend", sans-serif !important;
  }

  .secondary-links ul li a {
    /* font-size: 24px; */
  }

  .trash-btn {
    /* font-size: 24px; */
  }

  .btn-grp button {
    padding: 7px 14px;

    /* font-size: 22px; */
  }

  .btn-grp button i {
    /* font-size: 24px; */
  }

  .cart-sub-heading h6 {
    /* font-size: 26px; */
  }

  .pro-txt h3 {
    /* font-size: 26px; */
  }

  .pro-txt p {
    font-size: 20px;
    /* margin-bottom: 0px; */
  }

  .steps-bx {
    height: 120px;

    border-radius: 20px;
    line-height: 120px;
  }

  .step-no {
    left: -22.5px;
  }

  .step-no span {
    width: 45px;
    height: 45px;

    line-height: 45px;
  }

  .steps-bx span {
    /* font-size: 28px; */
  }

  .sticky-bx {
    position: sticky;
    top: 150px;
  }

  .product-search-bx .form-select,
  .product-search-bx .search-btn {
    /* font-size: 20px !important; */
  }

  .product-search-bx input::placeholder {
    /* Internet Explorer 10-11 */
    /* font-size: 20px !important; */
  }

  .head-no h6 {
    /* font-size: 32px; */
  }

  .heading-name h1 {
    /* font-size: 72px; */
  }

  .heading-logo i {
    /* font-size: 160px; */
  }

  .heading-txt p {
    /* font-size: 20px; */
  }

  .mb-60 {
    margin-bottom: 100px;
  }

  .masonry-content h6 {
    /* font-size: 24px; */
  }

  .about-links ul li a {
    /* font-size: 24px; */
  }

  .head-float {
    position: absolute;
    left: 0px;
    top: -50px;
  }

  .float-btn {
    width: 120px;
    line-height: 120px;
    height: 120px;
  }

  .lib-link {
    /* font-size: 24px; */
  }

  .serv-cont h3 {
    /* font-size: 32px; */
  }

  .service-icon i {
    /* font-size: 36px; */
  }

  /* .contact-heading p {
    font-size: 24px;
  } */
  .contact-heading h4 {
    /* font-size: 26px; */
  }

  .inserv-img {
    height: 500px;
  }

  .inserv-inner p {
    /* font-size: 28px; */
  }

  .inserv-inner {
    margin-top: -100px;
  }

  .test-bx {
    padding: 40px 40px;
  }

  .blg-large-img {
    width: 100%;
  }

  .modal-dialog {
    max-width: 900px;
  }

  .product-modal {
    height: 600px;
  }

  .max-500 {
    max-width: 850px;
  }

  .max-600 {
    max-width: 1000px;
  }

  .blg-img {
    height: 350px;
  }

  .menu-for-large .menu-item a {
    /* font-size: 50px; */
    padding: 10px 35px;
  }

  .mb-xl-200 {
    margin-bottom: 150px;
  }

  .strategy-text p {
    margin-top: 0;
    margin-bottom: 1rem;
    /* font-size: 24px; */
  }

  .strategy-title h1 {
    color: #1d1d1d;
    /* font-size: 32px; */
  }

  .strategy-title i {
    /* font-size: 42px; */
  }

  .about-heading h1,
  .contact-heading h1 {
    /* font-size: 64px; */
  }

  .panel span {
    /* font-size: 94px; */
  }

  .strat-txt h4 {
    /* font-size: 32px; */
  }

  .learn-more a {
    /* font-size: 24px; */
  }

  .trusted-img {
    height: 600px;
    text-align: center;
  }

  .nav-btn {
    /* font-size: 26px; */
  }

  .learn-btn,
  .trans-btn,
  .nav-btn-org {
    height: 80px;
  }

  .header .menu > .menu-item > a {
    /* font-size: 18px; */
  }

  .header .menu > .menu-item > .sub-menu > .menu-item > a {
    /* font-size: 18px; */
  }

  .logo-img {
    height: 80px;
  }

  .awards-big-txt h1 {
    font-size: 140px;
  }

  .award-number {
    /* font-size: 26px; */
    line-height: 50px;
    width: 50px;
    height: 50px;
  }

  .side-slider .slick-dots {
    bottom: -65px !important;
    right: 20px;
  }

  .side-slider .slick-dots li {
    margin-left: 30px !important;
  }

  .blog-img {
    height: 500px;
  }

  .small-blg-img {
    height: 180px;
  }

  .blog-date span {
    /* font-size: 22px; */
  }

  .number-desc h2,
  .float-txt h2 {
    /* font-size: calc(0.8vw + 0.3rem); */
    font-weight: 500;
  }

  .number-large {
    width: 140px;
    margin: 0px auto;
  }

  .number-bx {
    text-transform: capitalize;
  }

  .number-large h1 {
    text-align: center;
    padding: 0;
    -webkit-text-stroke-width: 1.5px;
  }

  .product-content h2 {
    /* font-size: 36px; */
  }

  .prod-link {
    line-height: 56px;
    /* font-size: 26px; */
  }

  .client-name p span {
    /* font-size: 24px; */
  }

  .product-vertical h2 {
    /* font-size: 24px; */
  }

  .img-wrapper {
    height: 420px;
  }

  .h-last {
    height: 840px;
  }

  .contact-form label {
    /* font-size: 24px; */
  }

  .contact-form input {
    height: 75px;
  }

  .form-select {
    height: 75px;
    /* font-size: 22px; */
    line-height: 65px;
  }
  .enquiry-sec .text-start label {
    /* font-size: 22px; */
  }
  .contact-form input::-webkit-input-placeholder,
  .contact-form textarea::-webkit-input-placeholder {
    /* font-size: 22px; */
    line-height: 65px;
  }

  .certificates .nav-pills .nav-item .nav-link {
    /* font-size: 32px; */
  }

  .trusted-txt p {
    /* font-size: 24px; */
  }

  .trusted-txt h3 {
    /* font-size: 32px; */
  }

  .member-img-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 25rem;
  }

  .member-img-700 {
    height: 750px !important;
  }

  .member-desc h2 {
    /* font-size: 36px; */
  }

  .member-desc p {
    /* font-size: 24px; */
  }
}

.py-100 {
  padding: 100px 0px;
}

@media (max-width: 991px) {
  .product-search-bx .form-sel {
    width: 155px;
  }

  .nav-btn-org {
    width: 45%;
    text-transform: uppercase;
  }
}

.izCZQC {
  background-color: #18191b !important;
}

.gTeoPJ {
  background-color: #18191b !important;
}

.preview-button {
  z-index: 10;
}

.bn40 {
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  padding: 4px 6px;
  color: #fff;
  text-decoration: none;
  font-family: ArtifaktLegend3;
}

.scrolling-active .bn40 {
  color: black;
}

.bn40::after {
  max-width: 100%;
  height: 2px;
  display: block;
  content: "";
  background: #be8400;
  opacity: 1;
  margin-bottom: -6px;
  margin-top: 1px;
  color: black;
}

.bn40:hover:after {
  animation: after 0.5s;
}

@keyframes after {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(100%);
  }
}

.bn40div {
  display: flex;
  background: #000000;
  background: transparent;
  padding: 10px;
}

.bn40div:hover {
  box-shadow: 0 5px 1 0px #00000028;
}

@font-face {
  font-family: artifak;
  src: url(../public/assets/ArtifaktElement-Regular.ttf);
}

* {
  font-family: "ArtifaktLegend", Arial, Helvetica, sans-serif;
}

iframe {
  width: 100%;
  height: 80vh;
}

.certificate-img img {
  height: 80vh;
  width: 65vh;
}

.certificate-img {
  display: flex;
  justify-content: center;
}

.container-uid {
  padding: auto 20px;
  margin: auto 10%;
}

.lg-large-img img {
  height: 5rem;
}

.new-sign-up {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  font-family: ArtifaktLegend;
  font-family: "ArtifaktLegend", sans-serif;
}

.new-sign-up::after {
  max-width: 100%;
  height: 2px;
  display: block;
  content: "";
  opacity: 1;
  margin-bottom: -6px;
  margin-top: 1px;
  color: black;
}

.new-sign-up:hover:after {
  animation: after 0.3s;
  background: #be8400;
}

.signup-icon {
  margin: auto 15px;
  cursor: pointer;
  font-size: 25px;
  color: black;
}

@keyframes after {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(100%);
  }
}

@media screen and (min-width: 1200px) {
  .new-sign-up {
    font-size: calc(0.8vw + 0.5rem);
    font-weight: 600;
    font-family: ArtifaktLegend;
    font-family: "ArtifaktLegend", sans-serif;
  }

  .new-sign-up:hover {
    color: #be8400;
  }
}

@media screen and (max-width: 1400px) {
  .new-sign-up {
    font-size: calc(0.8vw + 0.5rem);
    font-weight: 600;
    font-family: ArtifaktLegend;
    font-family: "ArtifaktLegend", sans-serif;
  }

  .new-sign-up:hover {
    color: #be8400;
  }
}

@keyframes hhh {
  0% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}

.pp-btn a {
  font-size: 15px;
  padding: 9px 21px;
  line-height: 29px;
  height: 46px;
}

.new-social-icon {
  gap: 1rem;
}

.new-social-icon a {
  font-size: 20px;
  border: 2px solid transparent;
  margin: 10px;
  margin-top: 5px;
}

.blog-box {
  text-decoration: none;
  color: #000;
}

.blog-box:hover {
  color: #000;
}

.blog-box img:hover {
  transform: scale(110%);
  transition: 0.5s;
}

.swiper-button-next,
.swiper-button-prev {
  color: #be8400 !important;
}

.popup-btn {
  background: white;
  border: 1px solid rgb(230, 228, 228);
  box-shadow: inset 0 2px 2px #0000007d;
  color: black !important;
  width: 15rem;
}

.popup-btn:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.265);
}

.popup-btn i {
  color: #be8400;
}

.popup-btn.btn1 {
  transform: translate(10%, 380%);
}

.popup-btn.btn2 {
  transform: translate(-95%, 870%);
}

.float-popup-bg h2 {
  font-size: 38px;
  font-weight: 400;
  margin: 0;
  color: #000;
}

.nav-btn-org.w-33 {
  border-radius: 0;
  border: transparent solid;
}

input.form-control {
  border-radius: 0;
}

button.search-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 0;
  box-shadow: none;
}

.side-blog {
  overflow-y: scroll;
  direction: rtl;
  max-height: 25rem;
  scrollbar-width: thin;
}

@media (min-width: 1650px) {
  .side-blog {
    max-height: 35rem;
  }
}

.side-blog:hover {
  scrollbar-color: black grey;
}

.scroll-left {
  direction: ltr;
}

.all-cat {
  border: none;
}

.slide-anim .my-3 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.btn-close {
  line-height: 15px;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 10px;
  top: 9px;
}

@media (min-width: 1200px) {
  .header .menu > .menu-item > a {
    font-size: calc(0.8vw + 0.5rem);
  }
  .blog-text h1 {
    font-size: 19px;
  }
}

@media (max-width: 993px) and (min-width: 767px) {
  .menu-for-large .menu-item a {
    padding: 10px 20px;
  }
}

@media (max-width: 767px) and (min-width: 520px) {
  .about-heading h1 {
    font-size: 25px;
  }

  .contact-heading h1 {
    font-size: 25px;
  }

  .menu-for-large .menu-item a {
    padding: 10px 10px;
  }

  .footer-menu-list {
    flex-direction: column;
    margin: 2px;
  }

  .new-social-icon {
    display: flex;
    flex-direction: row;
  }

  .footer-menu-list li {
    margin-right: 10px;
  }
}

@media (max-width: 520px) {
  .about-heading h1 {
    font-size: 25px;
  }

  .contact-heading h1 {
    font-size: 25px;
  }

  .menu-for-large .menu-item a {
    padding: 10px 5px;
  }

  .menu-for-large {
    display: flex;
    justify-content: center;
  }

  .nav-btn-org.w-33 {
    min-width: 12rem;
  }

  .footer-menu-list li a {
    font-size: 15px;
  }

  .footer-menu-list li {
    padding: 20px 0px;
    margin-right: 10px;
    list-style: none;
    white-space: nowrap;
  }

  .footer-menu-list {
    flex-direction: column;
    margin: 2px;
  }

  .new-social-icon {
    display: flex;
    flex-direction: row;
  }

  .product-buttons a {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

@media (min-width: 5000px) {
  .menu-for-large .menu-item a {
    font-size: 50px !important;
  }
}

.menu-for-large .menu-item a {
  font-size: calc(1.5vw + 0.5rem);
}

.number-large h1 {
  font-size: calc(1.5vw + 0.6rem);
}

@media (max-width: 1200px) {
  .nav-about {
    overflow: auto hidden;
  }
  /* .nav-about {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
    margin-bottom: 0;
    list-style: none;
    overflow: auto hidden;
    width: 1040px;
    white-space: nowrap;
    width: 98%;
    scrollbar-width: thin;
    border: 3px solid #be8400;
    border-radius: 15px;
    scrollbar-color: transparent transparent;
    height: 4rem;
  } */

  .invisible-scrollbar {
    scrollbar-width: none;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .nav-about ul {
    padding: 0;
  }

  /* .nav-about li {
    border-radius: 10px;
    padding: 16px 0px;
    margin: 0px 0px;
  } 

  .secondary-links ul {
    gap: 2rem;
  }*/

  /* .secondary-links ul li a {
    color: #be8400;
  } */

  .secondary-links ul li a.active {
    color: #be8400 !important;
    border-radius: 15px;
  }
}

.about-img {
  width: 100%;
}

.discover-us {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 800;
  font-family: "Times New Roman", Times, serif;
  background-image: linear-gradient(black 50%, white 50%);
  background-clip: text;
  color: transparent;
  transform: translateY(2.7rem);
  position: relative;
  z-index: 2;
}

.about-page {
  width: 60vw;
  margin: auto;
}

@media (max-width: 800px) {
  .about-page {
    width: 70vw;
    text-align: justify;
  }

  .discover-us {
    transform: translateY(2rem);
  }
}

.popup-btn {
  background: white;
  border: 1px solid rgb(230, 228, 228);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.492);
  padding: 2px 5px;
}

.popup-btn:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.265);
}

.popup-btn i {
  color: #be8400;
}

.btn-down.btn1 {
  transform: translate(0, -1.5rem);
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding: 4px 45px;
  background-color: #000;
  color: white;
  font-size: 1rem;
  letter-spacing: 0;
}

.btn-down.btn2 {
  transform: translate(0, 0.5rem);
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding: 5px 25px;
  background-color: #000;
  color: white;
  font-size: 1rem;
  letter-spacing: 0;
}

.btn-down.btn1 i,
.btn-down.btn2 i {
  color: #be8400;
  transform: translate(4px, -4px);
}

.float-popup-bg h2 span {
  font-size: 36px;
  font-weight: 500;
}

.float-popup-bg h2 {
  font-size: 36px;
  font-weight: 500;
  -webkit-text-stroke-width: 2px;
  margin: 0;
  color: #000;
}

.float-popup-bg p {
  font-size: 20px;
}

.orange-big {
  color: #be8400;
}
.sticky-bx {
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  padding: 15px 0;
  overflow-x: auto;
}
@media (max-width: 576px) {
  .float-popup-bg h2 {
    font-size: 7.5vmin;
  }

  .float-popup-bg p {
    font-size: 4.5vmin;
  }
}

@media (min-width: 1201px) {
  .sticky-bx {
    position: sticky;
    top: 110px;
    border-top: 1px solid rgba(128, 128, 128, 0.5);
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    margin: 16px 0;
    padding-top: 10px;
  }
}

.peb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
}

.peb-left-container,
.peb-right-container {
  text-align: left !important;
}

.peb-left-container h2,
.peb-right-container h2 {
  color: #be8400;
  font-weight: bold;
  font-size: 3.5vmin;
  text-align: center;
}

.peb-left strong {
  color: #1d1d1d;
  font-size: 20px;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
}

.peb-right strong {
  color: #1d1d1d;
  font-size: 20px;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
}

.peb-left,
.peb-right {
  display: flex;
  flex-direction: column;
  padding: 1vmin 5vmin;
  font-size: 2.5vmin;
  text-align: justify;
  width: 100%;
}
.peb-left {
  text-align: right;
}
.peb-right {
  text-align: left;
}
.peb-line {
  position: absolute;
  width: 0.5vmin;
  height: 100%;
  border-right: 0.5vmin black dotted;
  left: 53.2%;
  z-index: 2;
}

.pie-chart-container {
  position: relative;
}

.pie-chart-container i {
  position: absolute;
  font-size: 5vmin;
  top: 16%;
  left: 38%;
  color: white;
  color: #000;
  z-index: 100;
}

.pie-chart-container i:hover {
  opacity: 0.7;
  color: #000;
}

.pie-chart-container i:nth-of-type(2) {
  position: absolute;
  top: 16%;
  left: 54%;
}

.pie-chart-container i:nth-of-type(3) {
  position: absolute;
  top: 29%;
  left: 64%;
}

.pie-chart-container i:nth-of-type(4) {
  position: absolute;
  top: 45%;
  left: 65%;
}

.pie-chart-container i:nth-of-type(5) {
  position: absolute;
  top: 55%;
  left: 53%;
}

.pie-chart-container i:nth-of-type(6) {
  position: absolute;
  top: 55%;
  left: 38%;
}

.pie-chart-container i:nth-of-type(7) {
  position: absolute;
  top: 44%;
  left: 27%;
}

.pie-chart-container i:nth-of-type(8) {
  position: absolute;
  top: 29%;
  left: 27%;
}

.pie-chart-container-left {
  position: absolute;
  right: -28vmin;
  width: 55vmin;
  margin-top: 5vmin;
}

.pie-chart-container-right {
  position: absolute;
  left: -28vmin;
  width: 55vmin;
  margin-top: 5vmin;
}

@media (max-width: 1100px) {
  .peb-line {
    display: none;
  }

  #peb-container {
    display: none !important;
  }
}

@media (min-width: 1101px) {
  #peb-container-left {
    display: none !important;
  }

  #peb-container-right {
    display: none !important;
  }
}

.mypage {
  background: wheat !important;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 0.5)
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 0 15px 5px rgba(0, 0, 0, 0.2),
    0 0 10px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.mypage img {
  width: 25vmin;
}

.mypage p {
  padding: 5px;
}

.modal-header .nav-btn i {
  display: flex;
}

.modal-header .nav-btn:hover {
  background: #be8400;
}

.modal-header .modal-title {
  font-size: calc(1vw + 0.8rem);
}

.modal-dialog .modal-content .modal-body {
  padding: 0px;
}

/* @media (max-height: 480px) {
  .slideshow {
    background: red;
  }
} */

@media (max-width: 800px) {
  .certificate-img img {
    height: 80vh;
    width: 62vh;
  }
}

@media (max-width: 425px) {
  .certificate-img img {
    height: 50vh;
    width: 34vh;
  }
}

@media (max-width: 450px) {
  .custom-flex {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  .custom-btn {
    margin: 7px 0px !important;
  }
}

@media (max-width: 768px) {
  .secondary-links ul li a {
    font-size: 12px;
    text-wrap: nowrap;
    padding: 0 12px;
  }
  .form-section {
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .ftr-pd {
    padding-right: 0px !important;
  }
}

@media (max-width: 768px) {
  .sticky-bx {
    padding: 10px 0;
  }
  .pie-chart-container i {
    position: absolute;
    font-size: 5vmin;
    top: 18%;
    left: 38%;
    color: white;
    color: #000;
    z-index: 100;
  }

  .pie-chart-container i:nth-of-type(8) {
    position: absolute;
    top: 29%;
    left: 27%;
  }

  .pie-chart-container i:nth-of-type(7) {
    position: absolute;
    top: 45%;
    left: 26%;
  }

  .pie-chart-container i:nth-of-type(6) {
    position: absolute;
    top: 57%;
    left: 38%;
  }

  .pie-chart-container i:nth-of-type(2) {
    position: absolute;
    top: 17%;
    left: 53%;
  }

  .pie-chart-container i:nth-of-type(3) {
    position: absolute;
    top: 30%;
    left: 63%;
  }

  .pie-chart-container i:nth-of-type(4) {
    position: absolute;
    top: 46%;
    left: 64%;
  }

  .pie-chart-container i:nth-of-type(5) {
    position: absolute;
    top: 58%;
    left: 53%;
  }
}

@media (max-width: 450px) {
  .pie-chart-container i {
    position: absolute;
    font-size: 6vmin;
    top: 26%;
    left: 35%;
    color: white;
    color: #000;
    z-index: 100;
  }

  .pie-chart-container i:nth-of-type(8) {
    position: absolute;
    top: 33%;
    left: 20%;
  }

  .pie-chart-container i:nth-of-type(7) {
    position: absolute;
    top: 44%;
    left: 20%;
  }

  .pie-chart-container i:nth-of-type(6) {
    position: absolute;
    top: 51%;
    left: 35%;
  }

  .pie-chart-container i:nth-of-type(2) {
    position: absolute;
    top: 26%;
    left: 52%;
  }

  .pie-chart-container i:nth-of-type(3) {
    position: absolute;
    top: 34%;
    left: 66%;
  }

  .pie-chart-container i:nth-of-type(5) {
    position: absolute;
    top: 51%;
    left: 52%;
  }

  .pie-chart-container i:nth-of-type(4) {
    position: absolute;
    top: 44%;
    left: 67%;
  }
}

@media (max-width: 425px) {
  .partners .carousel-img {
    width: 7.9rem;
  }
}

.ftr-mrg {
  margin-top: 5px;
}

.home-p {
  text-align: center !important;
}

.carousel-item::after {
  background: none !important;
}
@media (min-width: 360px) {
  .our-team-model {
    width: 100%;
  }
}
@media (min-width: 760px) {
  .our-team-model {
    width: 50%;
  }
}
@media (min-width: 360px) and (max-width: 768px) {
  .certificates .nav-pills .nav-item .nav-link.active::after {
    opacity: 0 !important;
  }
  .pad-60 {
    padding: 0px 20px;
  }
  #root
    > div
    > div
    > div.pos-rel
    > div
    > div.row
    > div
    > div
    > div.col-lg-9.px-0.mb-lg-0.mb-4
    > div {
    padding: 3rem 0px !important;
  }
  .certificate-img img {
    width: 100%;
    height: auto;
  }
  .padd-100 {
    min-height: 100% !important;
  }
  .principles-layout .d-flex img {
    width: 100%;
    height: auto !important;
  }
}
.principles-layout > .d-flex {
  margin: 3rem 0 0 0;
  justify-content: space-between;
}
.principles-layout .d-flex img {
  max-width: 600px;
  height: 400px;
  /* align-self: center; */
  margin-left: auto;
  margin-right: auto;
}
.principles-layout .d-flex .principle-content {
  align-self: stretch;
  width: 90%;
  text-align: justify;
}

.principles-layout .d-flex .principle-content h1 {
  font-size: clamp(22px, 2vw, 30px);
  margin: 0;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700;
  text-transform: capitalize !important;
}
.principles-layout .d-flex .principle-content p {
  font-size: clamp(16px, 2vw, 19px);
  margin: 1.2rem 0 0 0;
}
.w-5p {
  width: 6px;
  height: auto;
  background: #f75e4d;
  margin-right: 1rem;
}
@media (max-width: 1199px) {
  .principles-layout .d-flex {
    flex-wrap: wrap;
  }
  .principles-layout > .d-flex:nth-child(odd) {
    flex-direction: column-reverse;
  }
  .principles-layout .d-flex .principle-content {
    width: 100%;
  }
  .principles-layout > .d-flex img {
    margin-top: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .principles-layout .d-flex img {
    max-width: 500px;
  }
}
@media (min-width: 1200px) {
  .principles-layout > .d-flex {
    padding: 2.5rem;
    padding-left: 0;
    padding-bottom: 0;
  }
  .principles-layout .d-flex .principle-content {
    width: 90%;
  }
  .principles-layout .d-flex .principle-content.left {
    margin-left: 2.5rem;
  }
  .principles-layout .d-flex .principle-content.right {
    margin-right: 2.5rem;
  }
  .menu-for-large .menu-item a {
    font-size: calc(2.5vw + 1rem);
  }
}

.peb--wrapper__right--heading,
.peb--wrapper__left--heading {
  color: #be8400;
  font-size: 3.5vmin;
  font-weight: 700;
}
.peb--wrapper {
  padding: 1rem;
  gap: 1rem;
}
.peb--wrapper__left--content > div strong,
.peb--wrapper__right--content > div strong {
  font-weight: bold;
  font-size: 1.3rem;
}
/* .peb--wrapper__left--content,.peb--wrapper__left--heading{ text-align: right;} */

/* .peb--wrapper__left,
.peb--wrapper__right { width: 50%; } */
.peb--wrapper__left--content,
.peb--wrapper__right--content,
.peb--wrapper__left,
.peb--wrapper__right {
  height: 100%;
}
/* .peb--wrapper__left--content p,
.peb--wrapper__right--content p{
  margin-bottom:0;
} */
@media (min-width: 768px) {
  .peb--wrapper__left--content > div.d-flex,
  .peb--wrapper__right--content > div.d-flex {
    min-height: 120px;
    margin-bottom: 0.9rem;
  }
}
@media (min-width: 992px) {
  .peb--wrapper__left--content,
  .peb--wrapper__right--content {
    max-width: 700px;
  }
}
.explore {
  background-color: white !important;
  color: #be8400!important;
  margin-left: 1rem;
}
.masonry-content .text-muted {
  color: rgba(29, 29, 29, 0.8);
}
/* .peb--wrapper__left--content{
  margin-left:auto;
} */


@media (max-width: 1154px){
  .FilterTeamsTab {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    justify-content: space-between;
  }
  .FilterTeamsTab button {
    text-wrap: nowrap;
  }
}

.swiper {
  width: 100%;
  height: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 4rem !important;
}

.swiper-slide {
  text-align: center;
  /* font-size: 18px; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-img img{
  /* height: 450px; */
  height: 350px;
}
@media (min-width: 360) and (max-width:767px){
  .filter-img img{
    max-width: 100% !important;
    height: 100% !important;
  }
}
@media (min-width: 768) and (max-width:1200px){
  .filter-img img{
    max-width: 500px;
    height: 350px;
  }
}




.serv-btn-cont{
text-align: center;
}



/* //////////////// */
/* CSS for Swiper arrow buttons with !important */
.swiper-button-next,
.swiper-button-prev {
  top: 10px !important; /* Adjust the top position as needed */
  bottom: unset !important;
  transform: translateX(0) !important;
}

/* Style for the buttons with !important (you can customize this as needed) */
.swiper-button-next,
.swiper-button-prev {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  /* Add more styles as needed */
}

/* //////////////// */


/* Add this to your CSS stylesheet */
.moved {
  margin-top: 4rem;
  transition: margin-top 0.3s ease; /* You can adjust the transition duration as needed */
}


@media (max-width: 500px) {
  .smosaBackground .shadow{
    padding: 10px !important;
  }
  .SaucesBackground .shadow{
    padding: 10px !important;
  }
  .dimsumBackground .shadow{
    padding: 10px !important;
  }
  .smosaBackground .shadow h1{
    font-size: 13px;
  }
  .SaucesBackground .shadow h1{
    font-size: 13px;
  }
  .dimsumBackground .shadow h1{
    font-size: 13px;
  }
  .prod-icon span {
    height: 100%;
  }
}
@media (max-width: 1000px) {
  .responsive > {
    flex-shrink:  0 !important;
    width: 50% !important;
  }
}






