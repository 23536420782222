.main-inner {
  /*       width: 176%; */
  // width: 280%;
  // width: 666%;
  display: flex;
  background: var(--color-yellow);
}
.content-main {
  scroll-snap-align: start;
  position: relative;
  // width: 87vw;
  width: 94vw;
  height: 100%;
  background: var(--color-yellow);
  /* border: 5px solid red; */
  padding: 0px 20px 20px 20px;
  font-family: "Times New Roman", Times, serif;
}
.content-in {
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  white-space: wrap;
}
.img-section {
  position: relative;
}
.img-section img{
  // max-width: none !important;

  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.img-section::after {
  content: "";
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: transparent;
  border: 1px solid white;
}
.content-main button {
  background-color: #161419;
  border-color: #161419;
  margin-top: 30px;
}
.btn-arrow {
  transform: rotate(-221deg);
  margin-left: 5px;
  width: 15px;
}

/* toggle buttun */

.toggle-button a{

display: flex;
justify-content: center;
align-items: center;
font-size: 20px;
font-weight: 4;
}

.arrow-left,
.arrow-right {
  width: 50px;
  height: 100%;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.arrow-right{
  background-color: white;
}

.arrow-left:hover,
.arrow-right:hover {
/*   background-color: #f0f0f0; */
}

.toggle-circle {
  position: absolute;
  top: 2px;
  width: 45px;
  height: 45px;
  background-color: white;
  transition: left 0.2s ease-in-out;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.main::-webkit-scrollbar{
  // height: 0;
  width: 2rem;
}
@media(min-width:767px){
  .main {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
  
    scroll-snap-type: x mandatory;
  
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    margin-top: -53px;
    scrollbar-width: thin;
  }
  .toggle-button {
    background: #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 50px;
    /* border: 1px solid #ccc; */
    /* border-radius: 25px; */
    overflow: hidden;
    left: 80%;
    top:-50px
  }
}
@media(max-width:767px){
  .main {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
  
    scroll-snap-type: x mandatory;
  
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  .toggle-button {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 50px;
    /* border: 1px solid #ccc; */
    /* border-radius: 25px; */
    overflow: hidden;
    left: 80%;
    top:-50px
  }
}

// read more
.content-in {
  max-height: 100px; /* Set a maximum height for the content */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.content-in.expanded {
  max-height: none;
}


// .content-main {
//   opacity: .5;
//   left:auto;
//   pointer-events: none;
//   transition: opacity 0.3s ease-in-out;
// }

// .active {
//   opacity: 1;
//   position: relative;
//   left: 0;
//   pointer-events: auto;
// }

.toggle-main{
  display: flex;
  justify-content: end;
  // margin-bottom: 30px;
  margin-bottom: 15px;
}
.toggle-main a{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 4;
}
.toggle-inner{
  background: #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 35px;
  /* border: 1px solid #ccc; */
  /* border-radius: 25px; */
  overflow: hidden;
  // left: 80%;
  // top: -50px;
}

.row:last-of-type{
  // border: 2px solid red;
  // margin-left: 50px;
}


.content-section h3{
  padding-top: 8px;
}




// pagination
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  padding-top: 20px;
  
  li {
    cursor: pointer;
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  li.active {
    background-color: #333;
    color: white;
  }
}

  // pagination



//////////////////////
/// /* Slideshow */

.slideshow {
  margin: 0 auto;
  // padding: 200px 0;
  padding-bottom: 80px;
  overflow: hidden;
  // max-width: 500px;
  background-color: grey; 
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  // height: 400px;
  width: 100%;
  // border-radius: 40px;
  // border: 20px solid red;
  // padding: 0 20px ;
}

/* Buttons */

.slideshowDots2 {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  // height: 20px;
  // width: 20px;
  border-radius: 50%;

  cursor: pointer;
  // margin: 15px 7px 0px;

  // background-color: #c4c4c4;
}

.slideshowDot.active {
  // background-color: #6a0dad;
}


.btn.btn-primary{
  margin-top:20px ;
  background: #000;
  border-color: transparent;
  
  
}
.btn.btn-primary:hover{
  border:1px #f90000;
}
  
  