.overview-title {
  margin: 30px 0;
  text-align: center;
}

.container-section {
  margin: 0 50px;
}

.overview-title h3 {
  font-size: 17px;
  color: black;
  line-height: 27px;
  font-weight: 400;
}

.overview-title h1 {
  font-size: 27px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
}

.overview-title h1 span {
  color: #be8400;
}

.custom-flex {
  margin: 30px 0 40px 0;
}

.custom-flex .quote {
  background-color: #be8400;
}

.custom-flex .download {
  color: #be8400 !important;
}

.content {
  font-size: 18x;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: #404040;
  ;
}

.work-section {
  padding: 50px;
  background-color: #232323;
  padding-bottom: 0;
}

.work-title h3 {
  font-size: 18px;
  color: white;
  line-height: 27px;
  font-weight: 400;
}

.work-title h1 {
  color: white;
  font-weight: 400;
  font-size: 35px;
  max-width: 740px;
}

.work-title h1 span {
  color: #f90000;
  font-size: KoHo;
}

.check-list-main {
  background-color: black;
  margin-left: -40px;
  height: 100%;
  /* max-height: 900px; */
  max-height: 535px;
  overflow-y: auto;
}

.check-list-main::-webkit-scrollbar {
  width: 5px;
}

.check-list-main::-webkit-scrollbar-track {
  background: #4e4a4a;
}

.check-list-main::-webkit-scrollbar-thumb {
  background: #989393;
}



.check-list {
  padding-left: 40px;
  padding-right: 20px;
}

.check-list .check-item {
  border-bottom: 1px solid #4b4b4b;
  /* padding: 15px 0; */
  padding: 35px 0;
  cursor: pointer;
}

@media (max-width:890px) {
  .check-list .check-item {
    padding: 20px 0;
  }
}

.check-list .check-item:last-child {
  border: none;
}

.check-list h3 {
  color: white;
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.arrow-box {
  width: 120px;
  height: 48px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f50000;
  margin-bottom: 50px;
}

.form-check-input {
  cursor: pointer;
}

.arrow-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}

.arrow-active {
  background-color: white;
}

.direction {
  transform: rotateY(-186deg);
}

.form-check-input:checked {
  background-color: #f50000;
  border-color: #f50000;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 0, 0, 0.25);
}

.check-list-content h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #f20000;
}

.activities {
  /* background-color: #f20000; */
  background-color: grey;
  /* padding: 50px; */
  padding: 25px 0 0 20px;

}

.activities h1 {
  font-size: 27px;
  font-weight: 100;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  max-width: 500px;
}

/*Tablet size */
@media (max-width: 768px) {
  .check-list-main {
    max-height: 555px;
  }

  .arrow-box {
    width: 100px;
    height: 45px;
  }
}

/*mobile size */
@media (max-width: 426px) {
  .container-section {
    margin: 0 20px;
  }

  .custom-flex {
    flex-direction: inherit;
    justify-content: space-between;
    margin: 20px 0 30px 0;
  }

  .content {
    font-size: 18px;
    line-height: 32px;
  }

  .work-section {
    padding: 20px;
  }

  .activities {
    padding: 20px;
  }

  .work-title h1 {
    font-size: 25px;
  }

  .check-list-content {
    margin-top: 15px;
  }
}

.main-heading {
  color: #9966CC;
  font-size: 24px;
}

.content-row {
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
}

@media(min-width:767px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none !important;
  }

  .firstHeading {
    width: 45.5%;
    text-align: right
  }

  .secondHeading {
    width: 9%;
    text-align: center;
  }

  .thirdHeading {
    width: 45.5%
  }

  .first {
    width: 45.5%;
    text-align: right
  }

  .second {
    width: 9%;
    text-align: center;
    padding-top: 10px;
  }

  .third {
    width: 45.5%
  }

  .check-list-content .images {
    height: 100%;
  }

  .check-list-content .direct {
    max-height: 385px;
  }

  .paragraph-content {
    min-height: 65px;
  }

  .image-container {
    height: 397px;
  }

  .check-list-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }
}

@media(max-width:767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block !important;
  }

  .firstHeading {
    width: 100%;
    text-align: left
  }

  .secondHeading {
    display: none;
    width: 0%;
  }

  .thirdHeading {
    width: 0%;
    display: none;
  }

  .first {
    width: 70%;
    text-align: justify;
  }

  .second {
    width: 30%;
    text-align: center;
    margin-top: 25px;
  }

  .third {
    width: 0%;
    display: none;
  }

  .check-list-content .images {
    width: 100%;
  }

  .check-list-content .direct {
    max-height: 385px;
  }

  .paragraph-content {
    min-height: 65px;
  }

  .image-container {
    height: 263px;
    width: 100%
  }

  .check-list-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: justify;
    color: white;
  }
}


.heading-text {
  color: #000;
  font-size: 35px !important;
  font-family: "ArtifaktLegend", sans-serif;
  font-weight: 700 !important;
}