/**********************************/
/***** Layout & Section Title *****/
/**********************************/
.font-artifakt{
    font-family: "ArtifaktLegend", sans-serif;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.column {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 400px;
    padding: 2rem;
    /* margin: 1rem; */
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    cursor: pointer;
    overflow:hidden;
}
@media (min-width: 360px){
    .column {
        padding: 1rem;
        margin: 0rem;
        width: 100%;
        max-width: 100%;
    }
    .column .team-3{
        margin: 0rem auto;

    }
}
@media (min-width: 576px) {
    .column {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .team-3 {
        max-width: 260px !important;
        width: auto !important;
        height: 260px !important;
        text-align: center;
        /* margin-bottom: 30px; */
        border-radius: 5px;
    }
}

@media (min-width: 768px) {
    /* .column {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        //max-width: 33.333333%;
        padding: .8rem;
        margin: .7rem;
    } */
    .column{
        flex: 0 0 40%;
    max-width: 40%;
    padding: 2rem 1rem 1rem 1rem;
    /* margin: 1rem; */
    }
    .team-3 {
        max-width: 220px !important;
        width: auto !important;
        height: 220px !important;
        text-align: center;
        /* margin-bottom: 30px; */
        border-radius: 5px;
    }
}

@media (min-width: 992px) {
    .column {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
        padding: 2rem 1rem 1rem 1rem;
        /* margin: 1rem; */
    }
}
@media (min-width: 1200px) {
    .column {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
        padding: 2rem;
        /* margin: 1rem; */
    }
    .team-3 .team-img img {
        width: 220px !important;
        height: 220px !important;
    }
    .team-3 .team-overlay {
        width: 220px !important;
        height: 220px !important;
    }
}

.section-title {
    width: 100%;
    text-align: center;
    padding: 45px 0 30px 0;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 5px;
    left: calc(50% - 25px);
    background: #353535;
}

.section-title h1 {
    color: #353535;
    font-size: 50px;
    letter-spacing: 5px;
    margin-bottom: 5px;
}

@media(max-width: 767.98px) {
    .section-title h1 {
        font-size: 40px;
        letter-spacing: 3px;
    }
}

@media(max-width: 567.98px) {
    .section-title h1 {
        font-size: 30px;
        letter-spacing: 2px;
    }
}
.team-3 {
    max-width: 260px;
    width: 260px;
    height: 260px;
    border-radius: 100%;
    text-align: center;
    /* margin-bottom: 30px; */
    border-radius: 5px;
}

.team-3 .team-img {
    overflow: hidden;
    width: 100%;
    height: 99%;
    border-radius: 100%;
}

img.our-team-model {
    /* max-width: 450px; */
    max-width: 250px;
    max-height: 450px;
    aspect-ratio: 1/1;
    border-radius: 0;
    object-fit: cover;
    object-position: top;
}
.team-3 .team-img img {
    width: 230px;
    height: 230px;
    aspect-ratio: 1/1;
    border-radius: 0;
    object-fit: cover;
    object-position: top;
    border-radius: 100%;
    background: #f7f5f3;
}
.team-3 .team-overlay {
    width: 230px;
    height: 230px;
    /* background: rgba(8, 61, 119, 0.502); */
    background: rgba(128, 128, 128, 0.5);
    margin: 0 auto;
    transform: translateY(15%);
    border-radius: 100%;
    transition: all .8s ease-in-out;
}
.team-3:hover .team-overlay {
    transform: translateY(-100%);
}
.team-3 .team-img img.bilal {
    object-position: 25% 25%;
}
img.our-team-model.bilal{
    object-position: 25% 25%;
}
.team-3 .team-img img:nth-child(3) {
    object-position: 25%;
}

.team-3 .team-content {
    margin: 16px auto 0 auto;
    background: #ffffff;
    font-weight: bold;
}
.team-3 .team-content h2 {
    font-size: 20px;
    font-weight: 600;
}
 
.team-3 .team-content h3 {
    font-size: 16px;
    text-transform:capitalize;
    font-weight: 400;
    margin-bottom:0;
    color: rgba(29, 29, 29, 0.8);
}

.team-3 .team-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

@media (min-width:720px) {
    .text-24{
        font-size: 19px;
    }
}
@media (min-width:1750px) {
    .text-24{
        font-size: 23px;
    }
}
.manager, 
#root > div > div > div.pos-rel > div > div.container > .manager.d-flex span{
    padding:1rem 1rem 1rem 0;
    /* font-family: sans-serif; */
    font-family: "ArtifaktLegend", sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize !important;
}
#root > div > div > div.pos-rel > div > div.container > .manager.d-flex {
    justify-content: space-center;
}

.team-ruler{
    height: 1.5px;
    width: 100%;
    background: rgb(202, 198, 198);
    opacity: 1;
    margin: 1rem;
    align-self: center;
}
.ourTeamSection .carousel-control-prev {
    color: #000;
}
.ourTeamSection .carousel-item img{
    margin: 1rem 0;
}
#root > div > div > div.pos-rel.ourTeamSection > div.container > span{
    padding-right:0;
}
.ourTeamSection .carousel-control-next-icon, .ourTeamSection .carousel-control-prev-icon {
    opacity: 0;
}
.ourTeamSection .FilterTeamsTab > button.btn-warning{
    background: transparent;
    border-color: transparent;
    color: #000 !important;

}
.ourTeamSection .FilterTeamsTab > button.btn-outline-secondary:hover{
    background: transparent;
    border-color: transparent;
    color: #000 !important;

}
.ourTeamSection .FilterTeamsTab > button.btn-outline-warning{
    color: #9966CC;
    border-color: #9966CC;
}
@media (min-width:500px){
    #root > div > div > div.pos-rel > div > div.container > .manager.d-flex span{
        white-space: nowrap;
    }
}
#root > div > div > div.pos-rel.ourTeamSection > div.container > span > hr:nth-child(1){
    margin-left:0;
    max-width: 200px;
}
#root > div > div > div.pos-rel.ourTeamSection > div.container > span > hr:nth-child(4){
    margin-right:0;
    max-width: 150px;
}
.officeSliderImages{
    justify-content: space-between;
}
@media (max-width: 1199px){
    .team-3:hover .team-overlay {
        transform: translateY(-100%);
    }
    .officeSliderImages{
        justify-content: center;
    }
    .officeSliderImages img{
        width: 100%;
    }
}
@media (min-width: 1155px){
    .FilterTeamsTab {
        justify-content: center;
    }
}
@media (min-width: 1200px){
    .officeSliderImages img{
        width: 550px;
        height:300px;
        object-fit: cover;
    }
}
@media (min-width: 1600px){
    .officeSliderImages img{
        width: 550px;
        height:300px;
        object-fit: cover;
    }
}
