@media (max-width: 870px) {
  .assistance-steps {
    display: none;
  }
}

.steps-bx {
  padding: 15px;
  border: 2px solid #9966CC;
  border-radius: 20px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;

  .step-txt {
    span {
      display: flex;
      align-items: center;
      font-size: calc(0.8vw + 0.5rem);
      i {
        display: flex;
        margin-right: 5px;
      }
    }
  }
}

.core-desc {
  p {
    font-size: calc(1vw + 0.3rem);
  }
}

.number-large {
  display: flex;
  justify-content: center;
}
