.block {
    margin-top: 100px !important;
}

.containers {
    width: calc(100% - 100px) !important;
    margin: 0 auto;
}

.first_detail {
    padding-top: 100px;
}

.first_detail h1 {
    font-size: 27px;
    line-height: 35px;
}

.details_text {
    max-width: 760px;
    line-height: 28px;
    color: #404040;
    font-size: 18px;
    margin: 0 auto;
}

.img_wrapper div {
    width: 48%;
    height: 533px;
}

.img_wrapper img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.full-image {
    height: 90vh;
}

.slide_img img {
    width: 520px;
    height: 291px;
    object-fit: cover;
}

.text-gray {
    color: #999999;
    font-size: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

.modal.show .modal-dialog {
    /* max-width: 100%; */
    max-width: 50rem;
}

.slide_imgs img {
    width: 400px;
    height: 491px;
    object-fit: cover;
}

.slider_model {
    max-width: 50% !important;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .img_wrapper div {
        width: 100%;
        height: 450px;
        margin-bottom: 20px;
    }

    .containers {
        width: calc(100% - 50px) !important;
        margin: 0 auto;
    }

    .block {
        margin-top: 80px;
    }

    .slide_img img {
        width: 100% !important;
        height: 291px;
        object-fit: cover;
    }

    .full-image {
        height: auto ;
    }
}