/* Option 2: Import via CSS */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

* {
    /* border: 2px  solid black; */

}

.sus-big-box {
    /* height: 40rem;
        width: 40rem; */
    aspect-ratio: 1 / 1;
    width: 100%;
    /* max-height: 100%; */
    height: 100vh;
    position: relative;
    overflow: hidden;
    border: 2px solid black;
}

.sus-box {
    width: 20%;
    height: 30%;
    position: absolute;
    left: 10%;
    /* right: 10%; */
    bottom: 40%;
    top: 60%;
    /* border: 2px solid red; */
    transition: .3s;
}

.sus-box :hover {
    color: #666666;

}

.sus-circle {
    /* width: 100%; */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 35%;
    border: 3px solid white;
    background-color: black;
    outline: 4px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b1 .sus-circle {
    background: #f06305;
    outline: 4px solid #f06305;

}

.b2 .sus-circle {
    background: #969b94;
    outline: 4px solid #969b94;

}

.b3 .sus-circle {
    background: #000000;
    outline: 4px solid #000000;

}

.b4 .sus-circle {
    background: #f0a26e;
    outline: 4px solid #f0a26e;
    transform: rotate(20deg);

}

.b5 .sus-circle {
    background: #454d40;
    outline: 4px solid #454d40;

}

.sus-big-circle {
    /* width: 90%; */
    /* width: 10rem; */
    /* height: 90%; */
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: -35%;
    top: 65%;
    border: 10px solid rgb(209, 206, 206);
    border: 10px solid #f1f2f2;
    z-index: 2;
    background: lavenderblush url(../Images/website-images/map.png);
    background-position: center;
    background-size: cover;
    transition: all 25s;

}
.sus-big-circle:hover{

    /* animation: mapRotate 25s infinite; */
    rotate: -180deg;


}
/* @keyframes mapRotate {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: -180deg;
    } 
}*/
.sus-circle,
.sus-big-circle {
    border-radius: 50%;
}

/* body {
    height: 100vh;
    display: grid;
    place-content: center;
} */

.sus-resizable {
height: 90vh;
/* width: 220px; */
position: relative;
/* resize: both;
overflow: hidden; */
}
/* @media (max-width:390px) { */
.sus-square2 {
    aspect-ratio: 1 / 1;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    place-content: center;

    background-color: #f1f2f2;
    /* border: 2px solid red; */
}

/* } */
.sus-square {
    overflow: hidden;
    aspect-ratio: 1 / 1;
    max-height: 100%;
    width: 100%;
    position: relative;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    place-content: center;

    background-color: #f1f2f2;
}
@media (max-width:480px) {
    .sus-square {
        /* overflow: visible; */
    }
    .sus-resizable {
        height: 60vh;
    }
}

/* @media (min-width:1200px) { */

.b1 {
    /* right: 10%; */
    bottom: 40%;

    top: 64%;
    left: 2%;
    /* border: 2px solid red; */
}

.b2 {
    /* right: 10%; */
    bottom: 40%;

    top: 47%;
    left: 18%;
    /* border: 2px solid yellow; */
}

.b3 {
    /* right: 10%; */
    bottom: 40%;

    top: 38%;
    left: 41%;
    /* border: 2px solid yellow; */
}

.b4 {
    /* right: 10%; */
    bottom: 40%;

    top: 47%;
    left: 63%;
    /* border: 2px solid yellow; */
    /* rotate: 20deg ; */
    /* transform: rotate(20deg) */
}

.b5 {
    /* right: 10%; */
    bottom: 40%;

    top: 65%;
    left: 79%;
    /* border: 2px solid green; */
}

.sus-info {
    position: absolute;
    transform: translateY(-78%);
    text-align: center;
    /* font-size: 1.5vmin; */
    font-size: 2vmin;
    color: #96a2b8;
    /* border: 2px solid green; */

}

.sus-info img {
    height: 9vmin;
    rotate: -45deg;

}

.sus-info h3 {
    padding: 0;
    color: black;
    text-transform: uppercase;
    font-size: 2.5vmin;
}
.sus-info p{
    /* margin-bottom: 0.5rem; */
}

.b1 .sus-info h3 {
    color: #f06305;
}

.b2 .sus-info h3 {
    color: #969b94;
}

.b3 .sus-info h3 {
    color: #000000;
}

.b4 .sus-info h3 {
    color: #f0a26e;
}

.b5 .sus-info h3 {
    color: #454d40;
}

.sus-circle i {
    color: white;
    font-size: 7vmin;

}

.sus-square2 h1 {
    position: absolute;
    top: 3%;
    left: 22%;
    color: #000;
    text-align: center;
    font-size: 3.8vmin;
    line-height: 4vmin;
    -webkit-text-stroke-width: 1px;

}

.sus-top-notch {
    position: absolute;
    left: 40%;

    top: -4%;
    height: 5%;
    width: 20%;
    background: #c29a77;
}