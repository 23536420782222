 .swiper-button-prev{
    /* background-color: #D9D9D9; */
    /* width: 80px;
    height: 80px; */
    border-radius: 50%;
    left: -35px !important;
    padding: 35px;
    top:40% !important;
}

.swiper-button-prev:hover{
    /* background-color: rgb(78, 77, 77);
    color: white; */
}


.swiper-button-prev::after {
    font-size: 30px !important;
    /* color: black; */
    padding-left: 30px;
}

.swiper-button-prev:hover::after {
    /* color: white; */
}

.swiper-button-next{
    /* background-color: #D9D9D9; */
    /* width: 80px;
    height: 80px; */
    padding: 35px;
    border-radius: 50%;
    right: -35px !important;
    top:40% !important;
}

.swiper-button-next:hover{
    /* background-color: rgb(78, 77, 77); */
    /* color: white; */
}


.swiper-button-next::after {
    font-size: 30px !important;
    /* color: black; */
    padding-right: 30px;
}

.swiper-button-next:hover::after {
    /* color: white; */
} 



/* new css */
.project-title{
    text-align: left;
    font-size: 17px;
    color: #000;
    margin: 2px;

}
.project-location{
    text-align: left;
    color: grey;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2px;


}