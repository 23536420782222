/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  video.slide {
    display: inline-block;
  
    height: 400px;
    height: 100vh;
    width: 100%;
    /* border-radius: 40px;  */
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
    z-index: 100;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  /* ////////////// */

  .slideshowDots {
    position: absolute;
    bottom: 20px; /* adjust the value to position the dots as desired */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* add z-index to bring the dots to the front */
  }
  
  .slideshowDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000; /* add desired styles for the dots */
    margin: 0 5px;
    cursor: pointer;
  }
  
  .slideshowDot.active {
    background-color: #fff; /* add desired styles for the active dot */
  }
  