@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
/* body{
  align-items: center;
  margin: auto;
} */

:root{
  /* --orange:#9966CC; */
  /* --orange: lightgreen; */
  /* --orange: white; */
  /* --orange: #666666;
  --orange: #eeeeee; */
  --grey: #cccccc;
  /* --light-black: #18191b; */
  --orange: #9966CC;

  /* font-size */
  /* --number-font:50px;
  --big-heading-font:25px;
  --heading-font:25px;
  --icon-font:40px;
  --list-font:30px; */

  --number-font: 45px;
    --big-heading-font: 25px;
    --heading-font: 25px;
    --icon-font: 35px;
    --list-font: 30px;
  /* font-size end*/
  
}
@media  (max-width:450px) {
  :root{
  --number-font: 30px;
    --big-heading-font: 25px;
    --heading-font: 25px;
    --icon-font: 20px;
    --list-font: 30px;
  }
}
@media  (max-width:770px) {
  :root{
  --number-font: 30px;
    --big-heading-font: 25px;
    --heading-font: 25px;
    --icon-font: 20px;
    --list-font: 30px;
  }
}

.service-container2{
  /* color: lightgreen; */
  padding: 2rem;
  margin: auto;
  margin-top: 5rem;
    /* border: 2px solid red; */
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    background: #666666;
    background: white;
    width: 95vw;
    /* height:100%; */
    transition: .5s;
}
.service-box{
  cursor: pointer;

    /* border: 1px solid var(--orange); */
    /* background: rgb(243, 201, 201); */
    /* background: var(--grey); */
    /* height: 5rem; */
    /* grid-row: 2fr; */
    display: flex;
    /* justify-content: space-between; */
    /* padding: 1rem; */
    /* border-radius: 5px; */
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
    transition: .3s;
background: #eeeeee;

}
.service-box:hover{
/* background: #ec5c4c; */
/* background: rgb(67, 216, 67); */
/* background: #7e7d7d; */
background: #cfcece;

}
.service-box:hover:before , .service-icon2:hover{
  /* background: #28292c;
  background: red; */
}
@keyframes color-change {
  
}
.service-box h2{
  margin-top: 0;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 35px;
  font-size: var(--heading-font);
  /* display: flex;
  flex-direction: column; */
}
.service-box h2::before{
  /* content: '_______';
  padding-bottom:10px ; */
}
.service-box::before{
  font-size: 18px; 
    content: 'oil & gas services';
    color:white;
    /* border: 1px solid var(--light-black); */
    background: var(--light-black) ;
    background: var(--orange) ;
    height: 3.5rem;
    position:absolute;
    transform: translate(1rem , -1rem);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px ;
    padding: 0px .5rem;
    width: 8rem;
  text-align: center;
}
.service-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    /* border: 1px solid blueviolet; */

}

.service-number{
  -webkit-text-stroke-width:1.5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 70px;
    font-size: var(--number-font);
    /* font-weight: 900; */
    /* padding-top: 2rem; */
    transform: translate(1rem , 1.7rem);
    /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif */
  }
.service-icon2{
    background: var(--light-black);
    padding: .5rem;
    height: 6rem;
    width: 10rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px ;
    transform: translate(1rem, .8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.service-box::before , .service-icon2{
  width: 9rem;
  background: var(--orange);
  /* transform: translateX(1rem); */
}

.service-icon2::before{
  color: palegoldenrod;
  content: '_______';
  padding-bottom:10px ;
   
}
.service-icon2 i{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-size: var(--icon-font)
}
.service-left div{
    
    /* border: 1px solid red; */

}

.service-content2{
  padding: 10px;
  /* width: 70%; */
  padding-top: 5px;
  padding-left: 0;
  border-top: 1px solid #666666;
  /* margin-top: 2.9rem; */
  margin-top: 2.5rem;
  margin-left:20px ;
}
.service-content2 ul{
  
  margin-bottom: 0;
  margin-top: 8px;
  padding-left: 25px;
}
.service-container2 .box-a {
    grid-area: analysis;
    /* min-width: 25rem; */
  }
  .service-container2 .box-b {
    grid-area: design;
  }
  .service-container2 .box-c {
    grid-area: detail;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
  }
  .service-container2 .box-c .service-left{
    align-items:flex-start;
    justify-content: space-between;
    /* text-align: left; */
  }

  .service-container2 .box-c .service-left .service-number{
    padding-left: 3rem;
    margin: 0;
    /* padding: 0; */
    margin-top: -50px;
    margin-bottom: 30px;
}
.service-container2 .box-c .service-content2{
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;

}

  .service-container2 .box-c::before{
   /* transform: translate(1rem , -14rem); */
   position: relative;
  }
  .box-o3{
    display: none;
  }

  @media screen and (max-width: 1100px) {
    .box-o h1{
      display: none;
    }
    .box-o span{
      display: none;
    }
    .box-o2{
      display: none;
    }
    .box-o3{
      display: block;
      grid-area: heading;
    }
    .service-container2{
      width: 95vw;
    }
    .service-container2 .box-c .service-left .service-number {
      padding-left: 3rem;
      margin-top: 0px;
    }
    .service-container2 .box-c::before{
     /* transform: translate(1rem , -14rem); */
     position: absolute;
    }
    .service-icon2{
      height: 7rem;
    }
  }
  @media screen and (max-width: 994px) {
    .service-icon2{
      height: 8rem;
    }
    .service-container2{
      width: 98vw;
      padding: 5px;
    }    
  
    }
  .service-container2 .box-c-2 {
  /* display: none; */
  grid-area: detail;

  }

  .service-container2 .box-d {
    grid-area: manufacturing;
  }
  .service-container2 .box-e {
    grid-area: support;
  }
  .service-container2 .box-o{
    grid-area: heading;
    font-size: 25px;
    font-size: var(--big-heading-font);
    
    text-transform: uppercase;
    /* width: 100%; */
  }
  .service-container2 .box-o{
  }
  .service-container2 .box-o h1{
    /* font-size: 25px; */
    margin: 0;
    
  }
  .service-container2 .box-o span{
    /* grid-area: heading2; */
    text-transform: capitalize;
    white-space: nowrap;
    font-size: var(--list-font);
    font-size: 20px;
    
  }
  .service-container2 {
    display: grid;
    /* width: 60vw; */
    /* grid-template-columns: 50px 50px 50px 50px; */
    grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
    grid-template-rows:  12rem 2rem 8rem 11rem ;
    /* grid-template-rows: auto; */
    grid-template-areas: 
      "analysis analysis analysis design design"
      "support support support heading detail"
      "support support support heading detail"
      "manufacturing manufacturing manufacturing manufacturing detail";
      gap: 2.5rem;
  }
  .service-box:nth-child(odd){
    /* background-color: lightsalmon; */
  }
  .service-box:nth-child(even){
    /* background-color: orangered; */
  }


  
      /* testing */
      .service-container2 .box-c {
        display: flex;
        
      }
      .service-container2 .box-c-2 {
        display: flex ;
        flex-direction: row;
        justify-content: left;
        /* border: 2px solid red; */
        display:none;
      }
      .service-container2 .box-c-2::before {
        /* width: 12rem; */
      
      }
      
      /* .service-container2 .box-c .service-left {
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        transform: translateY(-2rem);
      } */
     
      /* testing */
  @media  (max-width:1200px) {
    
    .service-box h2{
      font-size: 35px;
      font-size: var(--heading-font);
    }
    .service-number{
      font-size: 50px;
      font-size: var(--number-font);
      font-weight: 900;
      /* padding-top: 2rem; */
    }
    .service-container2 .box-o{
      /* width: max-content; */
    /* white-space: normal;
    white-space: nowrap; */


    }
     .service-container2 {
    display: grid;
    /* width: 60vw; */
    /* grid-template-columns: 50px 50px 50px 50px; */
    grid-template-columns: 1.6fr 1fr 1fr 1fr 1.5fr;
    grid-template-rows:  12rem 2rem 9rem 11.5rem ;
    /* grid-template-rows: auto; */
    grid-template-areas: 
      "analysis analysis analysis design design"
      "support support support heading detail"
      "support support support heading detail"
      "manufacturing manufacturing manufacturing manufacturing detail";
      gap: 1.5rem;
  }
  }

  @media  (max-width:1115px) {
    .service-container2 {
      display: grid;
      /* width: 60vw; */
      /* grid-template-columns: 50px 50px 50px 50px; */
      grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
      grid-template-rows: auto;
      grid-template-areas: 
        "heading heading heading heading heading"
        "analysis analysis analysis design design"
        "support support support support detail"
        "support support support support detail"
        "manufacturing manufacturing manufacturing manufacturing detail";
        gap: 1.5rem;
    }
    .service-container2 .box-o{
      /* width: max-content; */
    /* white-space: normal; */
    /* white-space: nowrap;
    border: 2px solid red; */


    }
    .service-box{
      justify-content:left;
       }
       .box-c .service-left .service-number{
        /* margin-top: 10px;
        background-color: black; */
        padding-top: 10px;
       }
  }
  @media  (max-width:770px) {
    .service-icon2{

    }
    .service-box h2{
      margin-top: 0;
    }
    .service-box{
   justify-content:left;
    }
    .service-container2 {
      /* display: grid; */
      /* width: 60vw; */
      /* grid-template-columns: 50px 50px 50px 50px; */
      /* grid-template-columns: 1fr; */
      /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
      /* grid-template-rows: auto; */
      /* grid-template-areas: 
        "."
        "analysis  "
        "design  "
        "detail "
        "manufacturing ";
        "support";
        gap: 1.5rem; */
    }
    .service-container2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: 
        "heading"
        "analysis"
        "design"
        "detail"
        "manufacturing"
        "support";
      gap: 1.5rem;
    }
    
    .service-container2 .box-c{
      display: none;
    }
    .service-container2 .box-c-2{
      display: flex;
      flex-direction: row;
    }
    .service-icon2{
      height: 7.5rem;
    }
    .service-icon2 i {
font-size: 30px;    }

.service-container2{
      width: 100vw;
      padding: 10px;
    }    
  
  }



  @media  (max-width:450px) {

    :root{
        /* font-size */
  --number-font:40px;
  --big-heading-font:15px;
  --heading-font:25px;
  --icon-font:20px;
  --list-font:20px;
  /* font-size end*/
    }
    .service-box::before , .service-icon2{
      width: 7.5rem;
      /* transform: translateX(1rem); */
    }
    
.service-container2{
      width: 100vw;
      padding: 5px;
    }    
  }


  .float-btn i::before{
    vertical-align: middle !important;
    padding-bottom: 5px;
  }

  [class^="bi-"]::before, [class*=" bi-"]::before{
    vertical-align: sub;
  }

.service-popup li{
  margin: 10px auto;
  font-size: 18px;
}
.service-popup-container{
  overflow: scroll;
  /* background-color: red; */
}